import React, { useEffect, useState } from 'react';
import { collection, getDocs, getFirestore, query, where, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useAuth } from '../../contexts/authContext';
import './TutorList.css';

const TutorList = () => {
    const db = getFirestore();
    const auth = getAuth();
    const navigate = useNavigate(); // Create the navigate function
    const [tutors, setTutors] = useState([]);
    const [error, setError] = useState(null);
    const { userLoggedIn } = useAuth();
    const [languageFilter, setLanguageFilter] = useState('');
    const [nationalityFilter, setNationalityFilter] = useState('');
    const [isPriceFilterVisible, setIsPriceFilterVisible] = useState(false);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(100);
    const [priceRangeFilter, setPriceRangeFilter] = useState([0, 100]);
    const [availabilityFilter, setAvailabilityFilter] = useState('');


    useEffect(() => {
        const fetchTutors = async () => {
            try {
                const tutorsCollection = collection(db, 'users');
                const q = query(tutorsCollection, where('role', '==', 'tutor'));
                const tutorSnapshot = await getDocs(q);
                const tutorList = tutorSnapshot.docs.map(doc => ({ uid: doc.id, ...doc.data() }));
                // For each tutor, fetch the numberOfLessons field directly from the tutor's document
                const updatedTutors = await Promise.all(tutorList.map(async (tutor) => {
                    try {
                        // Check if numberOfLessons is 0, and set to "NEW TUTOR" in that case
                        const lessons = tutor.numberOfLessons === 0 ? "NEW TUTOR" : `${tutor.numberOfLessons} Lessons` || 0;
                        // Return the tutor with the appropriate lessons value
                        return {
                            ...tutor,
                            lessons
                        };
                    } catch (lessonError) {
                        console.error(`Error fetching number of lessons for tutor ${tutor.uid}: `, lessonError);
                        // Return the tutor data even if fetching fails, with 0 lessons
                        return {
                            ...tutor,
                            lessons: 0 // Default to 0 lessons if fetching fails
                        };
                    }
                }));
                setTutors(updatedTutors);
            } catch (err) {
                console.error("Error fetching tutors: ", err);
                setError("Failed to load tutors. Please try again later.");
            }
        };

        fetchTutors();
    }, [db]);

    const handleBookTrial = async (tutorUID) => {
        if (userLoggedIn) {
            try {
                const studentUID = auth.currentUser?.uid;
                if (!studentUID) {
                    console.error("The student is not authenticated.");
                    return;
                }

                const studentRef = doc(db, "users", studentUID);
                const studentSnapshot = await getDoc(studentRef);

                if (studentSnapshot.exists()) {
                    const studentData = studentSnapshot.data();
                    if (studentData.alreadytooktrial) {
                        alert("You have already taken the trial.");
                        navigate('/tutorinfo');
                        return; // Stop further execution if trial has already been taken
                    }

                    // Save tutorUID, studentUID, and the action "booktrial" to localStorage
                    localStorage.setItem("tutorUID", tutorUID);
                    localStorage.setItem("studentUID", studentUID);
                    localStorage.setItem("action", "booktrial");

                    console.log("Tutor UID saved:", tutorUID);
                    console.log("Student UID saved:", studentUID);
                    console.log("Action saved:", "booktrial");

                    // Redirect to the /calendar page
                    navigate('/calendar');
                } else {
                    console.error("Student data does not exist.");
                }
            } catch (error) {
                console.error("Error saving to localStorage:", error);
            }
        } else {
            navigate('/register');
        }
    };

    // Toggle price filter visibility
    const togglePriceFilter = () => {
        setIsPriceFilterVisible(!isPriceFilterVisible);
    };

    // Close the price filter
    const closePriceFilter = () => {
        setIsPriceFilterVisible(false);
    };

    return (
        <div className="tutor-list">
            <h2 className='filter-title'>Choose your tutor according to your needs:</h2>
            <div className="filter-bar">
                <select value={languageFilter} onChange={(e) => setLanguageFilter(e.target.value)}>
                    <option value="">All Languages</option>
                    <option value="spanish">Spanish</option>
                    <option value="german">German</option>
                    <option value="english">English</option>
                    <option value="russian">Russian</option>
                    <option value="portuguese">Portuguese</option>
                </select>

                <select value={nationalityFilter} onChange={(e) => setNationalityFilter(e.target.value)}>
                    <option value="">All Nationalities</option>
                    <option value="mexican">Mexican</option>
                    <option value="german">German</option>
                    <option value="british">British</option>
                    <option value="russian">Russian</option>
                    <option value="brazilian">Brazilian</option>
                </select>


                <select value={availabilityFilter} onChange={(e) => setAvailabilityFilter(e.target.value)}>
                    <option value="">Any Availability</option>
                    <option value="morning">Morning</option>
                    <option value="afternoon">Afternoon</option>
                    <option value="evening">Evening</option>
                </select>

                {/* Button to toggle price filter */}
                <button onClick={togglePriceFilter}>
                    Price Filter
                </button>

                {/* Price Filter Popup */}
                {isPriceFilterVisible && (
                    <div className="price-filter-popup">
                        <h3>Set Price Range</h3>
                        <input
                            type="number"
                            value={minPrice}
                            onChange={(e) => setMinPrice(Number(e.target.value))}
                            placeholder="Min Price"
                        />
                        <input
                            type="number"
                            value={maxPrice}
                            onChange={(e) => setMaxPrice(Number(e.target.value))}
                            placeholder="Max Price"
                        />
                        {/* Close button */}
                        <button onClick={closePriceFilter}>
                            Close
                        </button>
                    </div>
                )}

            </div>


            {error && <p className="error-message">{error}</p>}
            {tutors.map((tutor, index) => (

                <div className="tutor-card">
                    <div className="tutor-content-wrapper">
                        <div className="tutor-content">
                            <div className="profile-container">
                                <img src="/img/profilepic1.jpg" alt={`${tutor.tutorname} profile`} className="profile-pic" />
                                <img src="/img/Mexico.png" alt="Country Flag" className="country-flag" />
                            </div>

                            <div className="tutor-info">
                                <div className="tutor-details">
                                    <h3 className="tutor-name">{tutor.tutorname}</h3>
                                    <div className="star-price">
                                        <p className="star-mobile"><span>⭐</span> {tutor.rating || '5'}</p>
                                        <p className="price-mobile">${tutor.price || 0} / h</p>
                                    </div>
                                    <p className="tutor-details">Speaks: {tutor.languages || 'Spanish, English, Portuguese and German'}</p>
                                </div>
                                <p className="tutor-description">
                                    {tutor.description || "I have been teaching Spanish for 6 years now with students from all ages and nationalities 😀."}
                                </p>
                                <p className="lessons">{tutor.lessons}</p>
                                <div className="buttons">
                                    <button className="view-profile-button">View Profile</button>
                                    <button className="book-trial-button" onClick={() => handleBookTrial(tutor.uid)}>Book Free Trial</button>
                                </div>
                            </div>
                            <div className="video-container">
                                <iframe
                                    sandbox="allow-scripts allow-same-origin allow-presentation"
                                    src="https://www.youtube.com/embed/OXR-u-i_WQQ"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Tutor Video"
                                ></iframe>
                                <div className="tutor-metrics">
                                    <p className="star"><span>⭐</span> {tutor.rating || '5'}</p>
                                    <p className="price">FROM: ${tutor.price || 0} / h</p>
                                </div>
                            </div>
                        </div>

                        {/* Gradient Line */}
                        <div className="gradient-line"></div>
                    </div>
                </div>
            ))}
        </div>
    );

};

export default TutorList;

import { createContext, useContext, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { auth } from '../../firebase/firebase';
import { onAuthStateChanged } from "firebase/auth";
import { increment, getFirestore, doc, getDoc, setDoc, arrayUnion, updateDoc, collection, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';

const db = getFirestore();

const CalendarContext = createContext();

export const useCalendarContext = () => useContext(CalendarContext);

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;




export const CalendarProvider = ({ children }) => {
    const navigate = useNavigate();
    const tutorUID = localStorage.getItem('tutorUID');
    const studentUID = localStorage.getItem('studentUID');
    const [clientId, setClientId] = useState('');//SUS
    const [clientSecret, setClientSecret] = useState('');
    const [refreshToken, setRefreshToken] = useState('');
    const [calendarIds, setCalendarIds] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    const TIME_SLOT_DURATION = 30;
    const HOURS_START = 0;
    const HOURS_END = 24;
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const TUTOR_WORKING_HOURS_START = 7;
    const TUTOR_WORKING_HOURS_END = 24;
    const TUTOR_TIME_ZONE = 'America/Mexico_City';



    const [currentWeek, setCurrentWeek] = useState(moment().startOf('week').add(1, 'days'));
    const [blockedSlots, setBlockedSlots] = useState([]);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [unavailableSlots, setUnavailableSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState(null);


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            // Always extract tutorUID from local storage
            const tutorUID = localStorage.getItem('tutorUID');

            if (tutorUID) { // Ensure the tutorUID exists in local storage
                // Fetch the tutor's Firestore document using tutorUID from local storage
                const tutorRef = doc(db, 'users', tutorUID);
                const tutorDoc = await getDoc(tutorRef);
                const tutorData = tutorDoc.data(); // The tutor's data from Firestore

                // Set the data for further use in your app
                setUserData(tutorData);  // Save the tutor's data in state
                setIsAuthenticated(true);  // Set the authentication state to true

                // Fetch sensitive data related to the tutor (e.g., calendar keys)
                const sensitiveDataRef = doc(db, 'users', tutorUID);
                const sensitiveDataDoc = await getDoc(sensitiveDataRef);
                if (sensitiveDataDoc.exists()) {
                    const sensitiveData = sensitiveDataDoc.data();
                    setClientId(sensitiveData.CLIENT_ID);
                    setClientSecret(sensitiveData.CLIENT_SECRET);
                    setRefreshToken(sensitiveData.REFRESH_TOKEN);
                    setCalendarIds(sensitiveData.calendarIds);
                    setDataLoaded(true);
                } else {
                    console.error('Sensitive data not found');
                }
            } else {
                setUserData(null);  // Reset tutor data if not found
                setIsAuthenticated(false); // Set to unauthenticated
            }
            setIsLoading(false); // Indicate loading is complete
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (dataLoaded) {
            const fetchDataOnce = async () => {
                let savedToken = localStorage.getItem('google_access_token');
                savedToken = await requestAccessTokenWithRefreshToken(refreshToken);
                let studentSavedToken = localStorage.getItem('google_student_access_token');
                studentSavedToken = await requestStudentAccessTokenWithRefreshToken(studentUID);
                await fetchData(savedToken);
                setIsLoading(false);
            };

            fetchDataOnce();
        }
    }, [dataLoaded, currentWeek, refreshToken,]);

    const requestAccessTokenWithRefreshToken = async (refreshToken) => {
        const tokenUrl = 'https://oauth2.googleapis.com/token';
        const body = new URLSearchParams({
            client_id: clientId,
            client_secret: clientSecret,
            refresh_token: refreshToken,
            grant_type: 'refresh_token',
        });

        try {
            const response = await fetch(tokenUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body.toString(),
            });

            const data = await response.json();

            if (response.ok) {
                localStorage.setItem('google_access_token', data.access_token);
                console.log('Access Token:', data.access_token);
                return data.access_token;
            } else {
                console.error('Error obtaining access token:', data);
                return null;
            }
        } catch (error) {
            console.error('Error obtaining access token:', error);
            return null;
        }
    };


    const requestStudentAccessTokenWithRefreshToken = async (studentUID) => {
        try {
            const tokenUrl = 'https://oauth2.googleapis.com/token';
            const studentDocRef = doc(db, 'users', studentUID);
            const studentDocSnap = await getDoc(studentDocRef);

            if (!studentDocSnap.exists()) {
                throw new Error('User document not found.');
            }

            const studentData = studentDocSnap.data();
            const refreshToken = studentData.REFRESH_TOKEN;

            if (!refreshToken) {
                throw new Error('Refresh token not found.');
            }

            const body = new URLSearchParams({
                client_id: clientId,
                client_secret: clientSecret,
                refresh_token: refreshToken, // Corrected key name
                grant_type: 'refresh_token',
            });

            const response = await fetch(tokenUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body.toString(),
            });

            const data = await response.json();

            if (response.ok) {
                localStorage.setItem('google_student_access_token', data.access_token);
                console.log('StudentAccess Token:', data.access_token);
                return data.access_token;
            } else {
                console.error('Error obtaining access token:', data);
                return null;
            }
        } catch (error) {
            console.error('Error obtaining access token:', error);
            return null;
        }
    };



    const fetchData = async (accessToken) => {
        if (accessToken) {
            const newBlockedSlots = await fetchBlockedSlots(accessToken);
            const newAvailableSlots = [];
            const newUnavailableSlots = [];
            let scheduledClasses = [];

            // Fetch scheduled classes from Firestore
            const studentsRef = collection(db, `users/${tutorUID}/students`);
            const studentsSnapshot = await getDocs(studentsRef);

            // Recorrer los estudiantes para obtener sus clases programadas
            studentsSnapshot.forEach(studentDoc => {
                const studentData = studentDoc.data();
                scheduledClasses = scheduledClasses.concat(studentData.scheduledClasses || []);
            });

            for (let day = 0; day < 7; day++) {
                for (let hour = HOURS_START; hour < HOURS_END; hour++) {
                    for (let minute = 0; minute < 60; minute += TIME_SLOT_DURATION) {
                        const slotStart = moment(currentWeek).add(day, 'days').set({ hour, minute });
                        const slotEnd = moment(slotStart).add(TIME_SLOT_DURATION, 'minutes');

                        // Check if the current slot is outside tutor's working hours
                        const tutorSlotStart = moment(slotStart).tz(TUTOR_TIME_ZONE).hour(TUTOR_WORKING_HOURS_START);
                        const tutorSlotEnd = moment(slotStart).tz(TUTOR_TIME_ZONE).hour(TUTOR_WORKING_HOURS_END);
                        const isOutsideWorkingHours = slotStart.isBefore(tutorSlotStart) || slotEnd.isAfter(tutorSlotEnd);

                        const isBlocked = newBlockedSlots.some(slot =>
                            moment(slot.start).isBefore(slotEnd) && moment(slot.end).isAfter(slotStart)
                        );

                        const isScheduledClass = scheduledClasses.some(classSlot =>
                            moment(classSlot.start).isBefore(slotEnd) && moment(classSlot.end).isAfter(slotStart)
                        );

                        if (isOutsideWorkingHours) {
                            // Add this slot to the unavailableSlots array if it's outside working hours
                            newUnavailableSlots.push({ start: slotStart, end: slotEnd });
                        } else if (isBlocked || isScheduledClass) {
                            // Add this slot to the blockedSlots array if it's blocked or scheduled
                            newBlockedSlots.push({ start: slotStart, end: slotEnd });
                        } else {
                            // Otherwise, it's available
                            newAvailableSlots.push({ start: slotStart, end: slotEnd });
                        }
                    }
                }
            }

            setBlockedSlots(newBlockedSlots);
            setAvailableSlots(newAvailableSlots);
            setUnavailableSlots(newUnavailableSlots); // Set the new unavailable slots
        }
    };





    const fetchBlockedSlots = async (accessToken) => {
        const startOfWeek = moment(currentWeek).startOf('isoWeek').tz(userTimeZone).toISOString();
        const endOfWeek = moment(currentWeek).endOf('isoWeek').tz(userTimeZone).toISOString();

        let allBlockedSlots = [];

        try {
            const fetchPromises = calendarIds.map(async (calendarId) => {
                const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?timeMin=${startOfWeek}&timeMax=${endOfWeek}&singleEvents=true&orderBy=startTime`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();
                const blockedSlots = data.items.map(event => ({
                    start: moment(event.start.dateTime || event.start.date).tz(userTimeZone).format(),
                    end: moment(event.end.dateTime || event.end.date).tz(userTimeZone).format(),
                }));
                return blockedSlots;
            });

            allBlockedSlots = await Promise.all(fetchPromises);
            allBlockedSlots = [].concat(...allBlockedSlots); // Flatten the array
        } catch (error) {
            console.error("Error fetching blocked slots:", error);
        }
        console.log(allBlockedSlots)
        return allBlockedSlots;
    };

    const handleNextWeek = () => {
        setCurrentWeek(moment(currentWeek).add(7, 'days'));
    };

    const handlePreviousWeek = () => {
        setCurrentWeek(moment(currentWeek).subtract(7, 'days'));
    };

    const handleSlotClick = (slot) => {
        if (availableSlots.some(s => moment(s.start).isSame(slot.start))) {
            setSelectedSlot(slot);
        }
    };


    const confirmBooking = async () => {

        const endSlot = moment(selectedSlot.start).add(1, 'hours');

        //PASO 1: Comprobar los créditos
        // Check credits before proceeding
        const tutorDocRef = doc(db, "users", studentUID, "tutors", tutorUID);
        const tutorDocSnap = await getDoc(tutorDocRef);

        let currentCredits = 0; // Initialize the currentCredits variable

        if (tutorDocSnap.exists()) {
            currentCredits = tutorDocSnap.data().credits || 0; // Safely assign credits
            console.log("Current credits:", currentCredits);
            if (currentCredits <= 0) {
                console.warn("No credits available to decrement.");
                alert("This student does not have enough credits to book the class.");
                return; // Stop the process if no credits are available
            }
        } else {
            console.error("Tutor document does not exist!");
            return; // Stop the process if the document doesn't exist
        }

        //PASO 2: CHECAR ESPACIOS SELECCIONADOS
        // Proceed with booking logic if credits are available
        if (selectedSlot) {
            const endSlot = moment(selectedSlot.start).add(1, 'hours');

            if (endSlot.isBefore(moment(currentWeek).add(7, 'days').set({ hour: HOURS_END }))) {
                console.log("Selected slot:", selectedSlot.start.format());
                const newBlockedSlots = [...blockedSlots, { start: selectedSlot.start.format(), end: endSlot.format() }];
                setBlockedSlots(newBlockedSlots);
                setSelectedSlot(null);
            }
        }
        let eventId = null; //DEFAULT NAME
        let studentEventId = null;

        // PASO 3 : MODIFICAR FIRESTORE ESTUDIANTE Y TUTOR (Y DISMINUCION DE CREDITOS)
        try {
            const classData = {
                day: selectedSlot.start.format('dddd'),
                hour: selectedSlot.start.format('HH:mm'),
                timezone: userTimeZone,
                cost: 0,
                start: selectedSlot.start.toISOString(),
                end: endSlot.toISOString(),
                eventId: `${studentUID + selectedSlot.start.format("ddddHH:mm")}` // Guardar un valor por defecto si la creación del evento falla
            };

            const docRef = doc(db, "users", studentUID, "tutors", tutorUID);
            console.log(`Ruta del documento en Firestore: users/${studentUID}/tutors/${tutorUID}`);

            const docSnap = await getDoc(docRef);
            console.log("¿Existe el documento?", docSnap.exists());

            if (docSnap.exists()) {
                // Actualizar el documento de Firestore si ya existe
                console.log("Actualizando las clases programadas existentes...");
                await updateDoc(docRef, {
                    scheduledClasses: arrayUnion(classData),
                });
                // Agregar la clase al documento del tutor en la ruta students/{studentUID}/scheduledClasses
                const tutorStudentDocRef = doc(db, "users", tutorUID, "students", studentUID);
                await updateDoc(tutorStudentDocRef, {
                    scheduledClasses: arrayUnion(classData)
                });
            } else {
                // Crear un nuevo documento si no existe
                console.log("Creando un nuevo documento para las clases programadas...");
                await setDoc(docRef, { scheduled: [classData] }, { merge: true });
            }
            console.log("Firestore actualizado con éxito.");

            // Decrementar el crédito en 1
            await updateDoc(tutorDocRef, {
                credits: currentCredits - 1,
            });
            console.log("Crédito decrementado en 1.");
            console.log(`Clase reservada desde ${selectedSlot.start.format('HH:mm')} hasta ${endSlot.format('HH:mm')} y agregada a tu calendario!`);
            // Obtener los datos del estudiante de Firestore
            const studentDocRef = doc(db, "users", studentUID);
            const studentDocSnap = await getDoc(studentDocRef);
            let studentName = 'StudentERROR';
            let studentEmail = 'EmailERROR';

            if (studentDocSnap.exists()) {
                studentName = studentDocSnap.data().name || 'StudentERROR';
                studentEmail = studentDocSnap.data().email || 'EmailERROR';
                console.log("Nombre del estudiante:", studentName);
                console.log("Correo electrónico del estudiante:", studentEmail);
                console.log("Firestore actualizado con éxito con la nueva clase de prueba para el tutor.");
            } else {
                console.error("¡El documento del estudiante no existe!");
                throw new Error("El documento del estudiante no existe.");
            }

        } catch (error) {
            console.error("Error durante el proceso:", error);
            alert(`Ha ocurrido un error: ${error.message}`);
        }


        //PASO 4: OBTENER EL ACCESS TOKEN DE GOOGLE
        let accessToken;
        try {
            accessToken = localStorage.getItem('google_access_token');
        } catch (error) {
            console.error('Error getting Google access token:', error);
        }

        let StudentAccessToken;
        try {
            StudentAccessToken = localStorage.getItem('google_student_access_token');
        } catch (error) {
            console.error('Error getting Student Google access token:', error);
        }

        // PASO 5: CREAR UN NUEVO EVENTO EN GOOGLE CALENDAR
        try {
            const classData = {
                day: selectedSlot.start.format('dddd'),
                hour: selectedSlot.start.format('HH:mm'),
                timezone: userTimeZone,
                cost: 0,
                start: selectedSlot.start.toISOString(),
                end: endSlot.toISOString(),
                eventId: `${studentUID + selectedSlot.start.format("ddddHH:mm")}` // Valor predeterminado para el eventId
            };

            // Retrieve the student's name from Firestore
            const studentDocRef = doc(db, "users", studentUID);
            const studentDocSnap = await getDoc(studentDocRef);
            let studentName = "Unknown Student";  // Default in case name is not found
            if (studentDocSnap.exists()) {
                const studentData = studentDocSnap.data();
                studentName = studentData.name || "Unknown Student";
                console.log("Student Name:", studentName);
            } else {
                console.log("No student document found.");
            }

            const event = {
                summary: `${studentName ? studentName : 'UserERROR'} APP`,
                start: {
                    dateTime: selectedSlot.start.format(),
                    timeZone: userTimeZone,
                },
                end: {
                    dateTime: endSlot.format(),
                    timeZone: userTimeZone,
                },
            };

            const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarIds[0]}/events`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(event),
            });

            if (response.ok ) {
                const eventData = await response.json();
                eventId = eventData.id;
                console.log("Google Calendar event created successfully. Event ID:", eventId);

                // Now create the event in the student's calendar
                let studentAccessToken = localStorage.getItem('google_student_access_token');
                if (!studentAccessToken) {
                    studentAccessToken = await requestStudentAccessTokenWithRefreshToken(studentUID);
                }

                

                // PASO ADICIONAL: OBTENER Y ACTUALIZAR EL EVENTO EXISTENTE EN FIRESTORE
                const docRef = doc(db, "users", studentUID, "tutors", tutorUID);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    // Buscar la clase programada existente
                    let scheduledClasses = docSnap.data().scheduledClasses || [];
                    let updatedClasses = scheduledClasses.map((cls) => {
                        // Verifica si esta clase es la misma que estamos programando (usando hora de inicio)
                        if (cls.start === classData.start) {
                            // Actualiza solo el eventId
                            return {
                                ...cls,
                                eventId: eventId, // Sobrescribe el eventId con el que se generó en Google Calendar
                            };
                        }
                        return cls; // No modificar las otras clases
                    });

                    // Actualizar Firestore con las clases modificadas
                    await updateDoc(docRef, { scheduledClasses: updatedClasses });
                    console.log("Event ID updated in Firestore successfully.");
                } else {
                    console.error("No existing document found for scheduled classes.");
                }

                // Actualizar el documento del estudiante en la ruta del tutor de la misma manera
                const tutorStudentDocRef = doc(db, "users", tutorUID, "students", studentUID);
                const tutorStudentDocSnap = await getDoc(tutorStudentDocRef);

                if (tutorStudentDocSnap.exists()) {
                    let scheduledStudentClasses = tutorStudentDocSnap.data().scheduledClasses || [];
                    let updatedStudentClasses = scheduledStudentClasses.map((cls) => {
                        if (cls.start === classData.start) {
                            return {
                                ...cls,
                                eventId: eventId,
                            };
                        }
                        return cls;
                    });

                    await updateDoc(tutorStudentDocRef, { scheduledClasses: updatedStudentClasses });
                    console.log("Event ID updated in Firestore successfully for student.");
                } else {
                    console.error("No existing student document found for scheduled classes.");
                }

            } else {
                console.warn("Failed to book the event in Google Calendar, eventId will not be updated.");
            }

            const studentCalendarResponse = await fetch(`https://www.googleapis.com/calendar/v3/calendars/primary/events`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${studentAccessToken}`, // Student's access token
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(event),
                });

                if (studentCalendarResponse.ok ) {
                    const eventData = await studentCalendarResponse.json();
                    eventId = eventData.id;
                    console.log("Google Calendar event created successfully. Event ID:", eventId);
    
                    // Now create the event in the student's calendar
                    let studentAccessToken = localStorage.getItem('google_student_access_token');
                    if (!studentAccessToken) {
                        studentAccessToken = await requestStudentAccessTokenWithRefreshToken(studentUID);
                    }
    
                    
    
                    // PASO ADICIONAL: OBTENER Y ACTUALIZAR EL EVENTO EXISTENTE EN FIRESTORE
                    const docRef = doc(db, "users", studentUID, "tutors", tutorUID);
                    const docSnap = await getDoc(docRef);
    
                    if (docSnap.exists()) {
                        // Buscar la clase programada existente
                        let scheduledClasses = docSnap.data().scheduledClasses || [];
                        let updatedClasses = scheduledClasses.map((cls) => {
                            // Verifica si esta clase es la misma que estamos programando (usando hora de inicio)
                            if (cls.start === classData.start) {
                                // Actualiza solo el eventId
                                return {
                                    ...cls,
                                    StudentEventId: eventId, // Sobrescribe el eventId con el que se generó en Google Calendar
                                };
                            }
                            return cls; // No modificar las otras clases
                        });
    
                        // Actualizar Firestore con las clases modificadas
                        await updateDoc(docRef, { scheduledClasses: updatedClasses });
                        console.log("Event ID updated in Firestore successfully.");
                    } else {
                        console.error("No existing document found for scheduled classes.");
                    }
    
                    // Actualizar el documento del estudiante en la ruta del tutor de la misma manera
                    const tutorStudentDocRef = doc(db, "users", tutorUID, "students", studentUID);
                    const tutorStudentDocSnap = await getDoc(tutorStudentDocRef);
    
                    if (tutorStudentDocSnap.exists()) {
                        let scheduledStudentClasses = tutorStudentDocSnap.data().scheduledClasses || [];
                        let updatedStudentClasses = scheduledStudentClasses.map((cls) => {
                            if (cls.start === classData.start) {
                                return {
                                    ...cls,
                                    StudentEventId: eventId,
                                };
                            }
                            return cls;
                        });
    
                        await updateDoc(tutorStudentDocRef, { scheduledClasses: updatedStudentClasses });
                        console.log("Event ID updated in Firestore successfully for student.");
                    } else {
                        console.error("No existing student document found for scheduled classes.");
                    }
    
                } else {
                    console.warn("Failed to book the event in Google Calendar (Student), eventId will not be updated.");
                }
        } catch (googleError) {
            console.error('Error while booking event in Google Calendar:', googleError);
        }



        //PASO ULTIMO: BLOQUEA EL HORARIO EN EL CALENDARIO DEL SISTEMA Y MARCA QUE YA TOMÓ CLASE
        try {
            console.log("Selected slot:", selectedSlot.start.format());
            const newBlockedSlots = [
                ...blockedSlots,
                { start: selectedSlot.start.format(), end: endSlot.format() }
            ];
            setBlockedSlots(newBlockedSlots);
            setSelectedSlot(null);
            // Muestra un mensaje indicando el día y la hora de la clase reservada
            const formattedDate = selectedSlot.start.format('dddd, MMMM Do YYYY'); // Ejemplo: "Monday, October 12th 2024"
            const formattedTime = `${selectedSlot.start.format('HH:mm')} to ${endSlot.format('HH:mm')}`; // Ejemplo: "14:00 to 15:00"
            alert(`You successfully booked a class on ${formattedDate} from ${formattedTime}.`);
        } catch (error) {
            console.error('Error during booking process:', error);
        }
    };



    const confirmReschedule = async () => {
        if (selectedSlot) {
            const endSlot = moment(selectedSlot.start).add(1, 'hours');

            if (endSlot.isBefore(moment(currentWeek).add(7, 'days').set({ hour: HOURS_END }))) {
                try {
                    console.log("Selected slot:", selectedSlot.start.format());

                    const newBlockedSlots = [...blockedSlots, { start: selectedSlot.start.format(), end: endSlot.format() }];
                    setBlockedSlots(newBlockedSlots);
                    setSelectedSlot(null);

                    const accessToken = localStorage.getItem('google_access_token');
                    console.log("Access Token:", accessToken);

                    // Fetch student and tutor document paths for Firestore
                    const tutorStudentDocRef = doc(db, "users", tutorUID, "students", studentUID);
                    const studentClassDocRef = doc(db, "users", studentUID, "tutors", tutorUID);

                    // Retrieve the student's name from Firestore
                    const studentDocRef = doc(db, "users", studentUID);
                    const studentDocSnap = await getDoc(studentDocRef);

                    let studentName = "Unknown Student";  // Default in case name is not found
                    if (studentDocSnap.exists()) {
                        const studentData = studentDocSnap.data();
                        studentName = studentData.name || "Unknown Student";
                        console.log("Student Name:", studentName);
                    } else {
                        console.log("No student document found.");
                    }

                    let newEventId = null;

                    // Try to create a new Google Calendar event
                    try {
                        const event = {
                            summary: `${studentName ? studentName : 'UserERROR'} APP`,
                            start: {
                                dateTime: selectedSlot.start.format(),
                                timeZone: userTimeZone,
                            },
                            end: {
                                dateTime: endSlot.format(),
                                timeZone: userTimeZone,
                            },
                        };

                        const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarIds[0]}/events`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(event),
                        });

                        if (!response.ok) {
                            throw new Error('Failed to book the event in Google Calendar');
                        }

                        const eventData = await response.json();
                        newEventId = eventData.id;
                        console.log("Google Calendar event created successfully. Event ID:", newEventId);

                    } catch (calendarError) {
                        console.error('Google Calendar booking failed:', calendarError);
                    }

                    const classData = {
                        day: selectedSlot.start.format('dddd'),
                        hour: selectedSlot.start.format('HH:mm'),
                        timezone: userTimeZone,
                        cost: 0,
                        start: selectedSlot.start.toISOString(),
                        end: endSlot.toISOString(),
                        eventId: newEventId
                    };

                    // Add new class to the tutor's Firestore document
                    await updateDoc(tutorStudentDocRef, {
                        scheduledClasses: arrayUnion(classData)
                    });

                    // Add new class to the student's Firestore document
                    await updateDoc(studentClassDocRef, {
                        scheduledClasses: arrayUnion(classData)
                    });

                    console.log("Firestore updated successfully with the new class for both tutor and student.");

                    // Remove the old class
                    const start = localStorage.getItem('classStart');
                    if (start !== null) {
                        console.log(`Removing class with start time ${start} from Firestore.`);

                        // Remove the old class from the student's document
                        const tutorUID = localStorage.getItem('tutorUID');
                        const studentClassSnap = await getDoc(studentClassDocRef);
                        if (studentClassSnap.exists()) {
                            const studentClasses = studentClassSnap.data().scheduledClasses || [];

                            // Find the index of the class that matches the start time
                            const classIndex = studentClasses.findIndex(classObj => classObj.start === start);

                            if (classIndex !== -1) { // Class found
                                const oldEventId = studentClasses[classIndex]?.eventId;

                                if (oldEventId) {
                                    // Remove the class from the student's array
                                    studentClasses.splice(classIndex, 1);
                                    await updateDoc(studentClassDocRef, { scheduledClasses: studentClasses });
                                    console.log(`Old class removed from student Firestore successfully.`);

                                    // Now remove the class from the tutor's document
                                    const tutorScheduledSnap = await getDoc(tutorStudentDocRef);
                                    const tutorScheduledClasses = tutorScheduledSnap.data().scheduledClasses || [];

                                    // Remove the same class from the tutor's array
                                    tutorScheduledClasses.splice(classIndex, 1);
                                    await updateDoc(tutorStudentDocRef, { scheduledClasses: tutorScheduledClasses });
                                    console.log(`Old class removed from tutor Firestore successfully.`);

                                    // Remove old event from Google Calendar
                                    try {
                                        const deleteResponse = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarIds[0]}/events/${oldEventId}`, {
                                            method: 'DELETE',
                                            headers: {
                                                'Authorization': `Bearer ${accessToken}`,
                                            }
                                        });

                                        if (!deleteResponse.ok) {
                                            throw new Error('Failed to delete the event from Google Calendar');
                                        }

                                        console.log('Old Google Calendar event deleted successfully.');
                                        setTimeout(() => navigate('/tutorinfo'), 0);
                                    } catch (deleteError) {
                                        console.error('Error deleting the old event from Google Calendar:', deleteError);
                                    }
                                }
                            } else {
                                console.log('No class found with the specified start time.');
                            }
                        }

                        localStorage.removeItem('classStart');
                    }


                    alert(`Class booked and updated for both tutor and student!`);

                } catch (error) {
                    console.error('Error during booking process:', error);
                    alert('Failed to book the event or update class data. Please try again.');
                }
            } else {
                alert('Please select a valid time slot for at least one hour.');
            }
        }
    };


    const confirmTrial = async () => {

        let classData = null;

        // PASO 1: VERIFICA SI HAY HORARIO SELECCIONADO
        if (!selectedSlot) {
            alert('Please select a valid time slot.');
            return;
        }

        const endSlot = moment(selectedSlot.start).add(1, 'hours');

        // PASO 2: VERIFICA SI ES VÁLIDO DENTRO DE LOS 7 DÍAS
        const isWithinValidRange = endSlot.isBefore(
            moment(currentWeek).add(7, 'days').set({ hour: HOURS_END })
        );

        if (!isWithinValidRange) {
            alert('Please select a valid time slot for at least one hour.');
            return;
        }

        //PASO 3: VERIFICAR SI EL ESTUDIANTE TOMÓ UNA CLASE PRUEBA YA
        const studentRef = doc(db, "users", studentUID);
        const studentSnapshot = await getDoc(studentRef);

        if (studentSnapshot.exists()) {
            const studentData = studentSnapshot.data();

            if (studentData.alreadytooktrial) {
                alert("You have already taken the trial.");
                return;
            } else {
                console.log("Student has not taken the trial yet.");
            }
        }

        let eventId = null; //DEFAULT NAME
        let studentEventId = null;

        // PASO 4 : MODIFICAR FIRESTORE ESTUDIANTE Y TUTOR
        try {
            // Modificar Firestore del estudiante
            classData = {
                day: selectedSlot.start.format('dddd'),
                hour: selectedSlot.start.format('HH:mm'),
                timezone: userTimeZone,
                cost: 0,
                start: selectedSlot.start.toISOString(),
                end: endSlot.toISOString(),
                eventId: `${studentUID + selectedSlot.start.format("ddddHH:mm")}` // Esto será null si Google Calendar falla
            };

            const docRef = doc(db, "users", studentUID, "tutors", tutorUID);
            await setDoc(docRef, { scheduled: [classData] }, { merge: true });

            // Obtener el nombre del tutor
            const tutorRef = doc(db, "users", tutorUID);
            const tutorSnapshot = await getDoc(tutorRef);
            const tutorData = tutorSnapshot.data();
            const tutorName = tutorData.name;

            const studentTutorRef = doc(db, "users", studentUID, "tutors", tutorUID);
            await setDoc(studentTutorRef, {
                credits: 0,
                name: tutorName,
                tutor: tutorUID,
                cost: 0
            }, { merge: true });

            console.log("Student Firestore updated successfully with the new trial class.");

            // Modificar Firestore del tutor
            const studentDocRef = doc(db, "users", studentUID);
            const studentDocSnap = await getDoc(studentDocRef);

            if (studentDocSnap.exists()) {
                const studentData = studentDocSnap.data();
                const studentName = studentData.name;
                const tutorStudentDocRef = doc(db, "users", tutorUID, "students", studentUID);
                const tutorDocRef = doc(db, "users", tutorUID);

                // Actualiza el documento del estudiante con el nombre y las clases agendadas
                await setDoc(tutorStudentDocRef, {
                    name: studentName,
                    scheduledClasses: arrayUnion(classData)
                }, { merge: true });
                // Actualiza el documento del tutor incrementando el número de lecciones
                await updateDoc(tutorDocRef, {
                    numberOfLessons: increment(1)
                });
                console.log("Firestore updated successfully with the new trial class for the tutor.");
            } else {
                throw new Error("No student found with the given studentUID.");
            }

        } catch (error) {
            console.error("Error updating Firestore (student/tutor):", error);
            return; // Si falla el paso 4 o 5, se detiene todo
        }

        //PASO 5: OBTENER EL ACCESS TOKEN DE GOOGLE
        let accessToken;
        try {
            accessToken = localStorage.getItem('google_access_token');
        } catch (error) {
            console.error('Error getting Google access token:', error);
        }

        // PASO 6: CREAR UN NUEVO EVENTO EN GOOGLE CALENDAR
        try {
            const classData = {
                day: selectedSlot.start.format('dddd'),
                hour: selectedSlot.start.format('HH:mm'),
                timezone: userTimeZone,
                cost: 0,
                start: selectedSlot.start.toISOString(),
                end: endSlot.toISOString(),
                eventId: `${studentUID + selectedSlot.start.format("ddddHH:mm")}` // Valor predeterminado para el eventId
            };

            // Retrieve the student's name from Firestore
            const studentDocRef = doc(db, "users", studentUID);
            const studentDocSnap = await getDoc(studentDocRef);
            let studentName = "Unknown Student";  // Default in case name is not found
            if (studentDocSnap.exists()) {
                const studentData = studentDocSnap.data();
                studentName = studentData.name || "Unknown Student";
                console.log("Student Name:", studentName);
            } else {
                console.log("No student document found.");
            }

            const event = {
                summary: `${studentName ? studentName : 'UserERROR'} APP`,
                start: {
                    dateTime: selectedSlot.start.format(),
                    timeZone: userTimeZone,
                },
                end: {
                    dateTime: endSlot.format(),
                    timeZone: userTimeZone,
                },
            };

            const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarIds[0]}/events`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(event),
            });


            if (response.ok) {
                const eventData = await response.json();
                eventId = eventData.id;
                console.log("Google Calendar event created successfully. Event ID:", eventId);

                // Now create the event in the student's calendar
                let studentAccessToken = localStorage.getItem('google_student_access_token');
                if (!studentAccessToken) {
                    studentAccessToken = await requestStudentAccessTokenWithRefreshToken(studentUID);
                }

                const studentCalendarResponse = await fetch(`https://www.googleapis.com/calendar/v3/calendars/primary/events`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${studentAccessToken}`, // Student's access token
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(event),
                });

                if (studentCalendarResponse.ok) {
                    const eventData = await studentCalendarResponse.json();
                    studentEventId = eventData.id;
                    console.log("Google Calendar event created successfully. Student Event ID:", StudentEventId);
                }

                if (studentCalendarResponse.ok) {
                    const studentEventData = await studentCalendarResponse.json();
                    console.log("Google Calendar event created successfully for the student. Event ID:", studentEventData.id);
                } else {
                    console.warn("Failed to book the event in Google Calendar for the student, eventId will not be updated.");
                }

                // PASO 7: OBTENER Y ACTUALIZAR EL EVENTO EXISTENTE EN FIRESTORE
                const docRef = doc(db, "users", studentUID, "tutors", tutorUID);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    // Buscar la clase programada existente
                    let scheduledClasses = docSnap.data().scheduled || [];
                    let updatedClasses = scheduledClasses.map((cls) => {
                        // Verifica si esta clase es la misma que estamos programando (usando hora de inicio)
                        if (cls.start === classData.start) {
                            // Actualiza solo el eventId
                            return {
                                ...cls,
                                eventId: eventId, // Sobrescribe el eventId con el que se generó en Google Calendar
                                studentEventId: studentEventId
                            };
                        }
                        return cls; // No modificar las otras clases
                    });

                    // Actualizar Firestore con las clases modificadas
                    await updateDoc(docRef, { scheduled: updatedClasses });
                    console.log("Event ID updated in Firestore successfully.");
                } else {
                    console.error("No existing document found for scheduled classes.");
                }

                // Actualizar el documento del estudiante en la ruta del tutor de la misma manera
                const tutorStudentDocRef = doc(db, "users", tutorUID, "students", studentUID);
                const tutorStudentDocSnap = await getDoc(tutorStudentDocRef);

                if (tutorStudentDocSnap.exists()) {
                    let scheduledStudentClasses = tutorStudentDocSnap.data().scheduledClasses || [];
                    let updatedStudentClasses = scheduledStudentClasses.map((cls) => {
                        if (cls.start === classData.start) {
                            return {
                                ...cls,
                                eventId: eventId,
                                studentEventId: studentEventId
                            };
                        }
                        return cls;
                    });

                    await updateDoc(tutorStudentDocRef, { scheduledClasses: updatedStudentClasses });
                    console.log("Event ID updated in Firestore successfully for student.");
                } else {
                    console.error("No existing student document found for scheduled classes.");
                }

            } else {
                console.warn("Failed to book the event in Google Calendar, eventId will not be updated.");
            }
        } catch (googleError) {
            console.error('Error while booking event in Google Calendar:', googleError);
        }




        // PASO 8: CORREO DE NOTIFICACIÓN
        try {
            // Prepare email params
            const studentDocRef = doc(db, "users", studentUID);
            const studentDocSnap = await getDoc(studentDocRef);
            const studentName = studentDocSnap.data().name || 'Student';
            const studentEmail = studentDocSnap.data().email || 'Email';

            const emailParams = {
                student_name: studentName,
                meeting_time: `${selectedSlot.start.format('dddd, HH:mm')} to ${endSlot.format('HH:mm')}`,
                meeting_timezone: timeZone,
                student_email: studentEmail,
            };

            // Send email notification
            if (studentEmail !== 'EmailERROR') {
                await emailjs.send(
                    'service_smxhks2',
                    'template_r3a6jey',
                    emailParams,
                    '47-SnE6ovuBU5bO6x',
                    {
                        to_email: studentEmail
                    }
                );
                console.log("Notification email sent successfully.");
            } else {
                console.error("No valid email found for student.");
            }
        } catch (error) {
            console.error("Error sending email notification:", error);
        }

        // Continuar con el resto del proceso aunque el correo falle

        //PASO ULTIMO: BLOQUEA EL HORARIO EN EL CALENDARIO DEL SISTEMA Y MARCA QUE YA TOMÓ CLASE
        try {
            console.log("Selected slot:", selectedSlot.start.format());
            const newBlockedSlots = [
                ...blockedSlots,
                { start: selectedSlot.start.format(), end: endSlot.format() }
            ];
            setBlockedSlots(newBlockedSlots);
            setSelectedSlot(null);
            const studentDocRef = doc(db, "users", studentUID);
            await updateDoc(studentDocRef, { alreadytooktrial: true });
            alert('Congratualtions your first class has been booked successfully! :D');
            setTimeout(() => navigate('/tutorinfo'), 0);
        } catch (error) {
            console.error('Error during booking process:', error);
        }
    };


    //TRIAL SOBRESCRIBE EL PERFIL COMPLETO (CUIDADO)



    const handleCancel = async (studentUID, classItem, tutorUID) => {
        try {
            console.log("1. Start class cancellation process");

            // Eliminar la clase del estudiante en Firestore
            const classRef = doc(db, "users", studentUID, "tutors", tutorUID);
            const classSnapshot = await getDoc(classRef);
            const classData = classSnapshot.data();
            const classIndex = classData.scheduledClasses.findIndex(item => item.start === classItem.start);
            console.log("2. Fetched class data from Firestore");

            if (classIndex === -1) {
                throw new Error('Class not found');
            }
            console.log("3. Class found in Firestore");

            const eventId = classData.scheduledClasses[classIndex].eventId; // Extraer el eventId

            // Remover la clase del estudiante en Firestore
            const updatedClasses = classData.scheduledClasses.filter((item) => item.start !== classItem.start);
            await updateDoc(classRef, { scheduledClasses: updatedClasses });
            console.log(`4. Class canceled for student ${studentUID} and tutor ${tutorUID}`);

            // Ahora también eliminar la clase del tutor en Firestore
            const tutorClassRef = doc(db, "users", tutorUID, "students", studentUID);
            const tutorClassSnapshot = await getDoc(tutorClassRef);
            const tutorClassData = tutorClassSnapshot.data();

            if (tutorClassData && tutorClassData.scheduledClasses) {
                const updatedTutorClasses = tutorClassData.scheduledClasses.filter(item => item.start !== classItem.start);
                await updateDoc(tutorClassRef, { scheduledClasses: updatedTutorClasses });
                console.log(`5. Class canceled from tutor's document for student ${studentUID}.`);
            } else {
                console.warn(`Tutor document for ${tutorUID} does not contain scheduled classes for student ${studentUID}.`);
            }

            // Fetch calendarId from the tutor's document
            const tutorRef = doc(db, "users", tutorUID);
            const tutorSnapshot = await getDoc(tutorRef);
            const tutorData = tutorSnapshot.data();
            const calendarId = tutorData.calendarIds[0]; // Get the first calendarId
            console.log("6. Fetched calendar ID from tutor's document");

            // Now delete the event from Google Calendar using the eventId and calendarId
            const accessToken = localStorage.getItem('google_access_token');
            try {
                const deleteResponse = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                console.log("7. Sent delete request to Google Calendar");

                if (!deleteResponse.ok) {
                    throw new Error('Failed to delete the event from Google Calendar');
                }
                console.log('8. Google Calendar event deleted successfully.');
            } catch (error) {
                console.error("Error deleting Google Calendar event:", error);
                // Continuar el proceso aunque falle la eliminación del evento en Google Calendar
            }

            // Restablecer el crédito del estudiante
            const tutorDocRef = doc(db, "users", studentUID, "tutors", tutorUID);
            const tutorDocSnap = await getDoc(tutorDocRef);
            console.log("9. Fetched student's tutor document from Firestore");

            if (tutorDocSnap.exists()) {
                const currentCredits = tutorDocSnap.data().credits || 0;
                await updateDoc(tutorDocRef, { credits: currentCredits + 1 });
                console.log(`10. Credit added back to student ${studentUID}.`);

                // Decrementar el número de clases del tutor
                const tutorMainDocRef = doc(db, "users", tutorUID);
                const tutorMainDocSnap = await getDoc(tutorMainDocRef);
                console.log("11. Fetched tutor's main document from Firestore");

                if (tutorMainDocSnap.exists()) {
                    const currentNumberOfLessons = tutorMainDocSnap.data().numberOfLessons || 0;
                    await updateDoc(tutorMainDocRef, {
                        numberOfLessons: currentNumberOfLessons > 0 ? increment(-1) : 0 // Decrementa solo si es mayor que 0
                    });
                    console.log(`12. Decremented one lesson for tutor ${tutorUID}.`);
                } else {
                    console.warn(`13. Main tutor document for ${tutorUID} does not exist.`);
                }

            } else {
                console.warn(`14. Tutor document for ${tutorUID} does not exist for student ${studentUID}`);
            }

        } catch (error) {
            console.error("Error during class cancellation: ", error);
            setError("Error during class cancellation: " + error.message);  // Actualiza el estado de error
        }
    };





    const calendarContextValue = {
        currentWeek,
        setCurrentWeek,
        blockedSlots,
        availableSlots,
        unavailableSlots,
        selectedSlot,
        setSelectedSlot,
        isAuthenticated,
        setIsAuthenticated,
        isLoading,
        userData, // Include userData in context value
        handleNextWeek,
        handlePreviousWeek,
        handleSlotClick,
        confirmBooking,
        confirmReschedule,
        confirmTrial,
        handleCancel,
    };




    return (
        <CalendarContext.Provider value={calendarContextValue}>
            {children}
        </CalendarContext.Provider>
    );
};
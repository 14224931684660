import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Main from "./components/Main/Main";
import Courses from "./components/Courses/Courses";
import AboutUs from "./components/AboutUs/AboutUs";
import Footer from "./components/Footer/Footer";
import Calendar from "./components/Calendar/Calendar";
import TutorInfo from "./components/TutorInfo/TutorInfo";
import Students from "./components/Students/Students";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/register";
import { AuthProvider } from "./contexts/authContext";
import ProtectedRoute from "./components/Routing/ProtectedRoute";
import Unauthorized from "./components/Unauthorized/Unauthorized";
import { CalendarProvider } from "../src/contexts/CalendarContext/calendarContext2";
import TutorsList from "./components/TutorList/TutorList";
import Settings from "./components/Settings/Settings";
import Privacy from "./components/Privacy/Privacy";
import MembershipOptions from "./components/Membership/Membership";


const App = () => {
  return (
    <Router>
      <CalendarProvider>
        <AuthProvider>
          <div>
            <Header />
            <div className="underheader">
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <Main />
                      <AboutUs />
                      <Courses />
                      {/* <Calendar2 /> */}
                    </>
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/tutors" element={<TutorsList />} />

                <Route
                  path="/calendar"
                  element={
                    <ProtectedRoute
                      element={<Calendar />}
                      allowedRoles={['student', 'tutor', 'admin']}
                    />
                  }
                />
                <Route
                  path="/tutorinfo"
                  element={
                    <ProtectedRoute
                      element={<TutorInfo />}
                      allowedRoles={['student', 'admin']}
                    />
                  }
                />
                <Route
                  path="/students"
                  element={
                    <ProtectedRoute
                      element={<Students />}
                      allowedRoles={['tutor', 'admin']}
                    />
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <ProtectedRoute
                      element={<Settings />}
                      allowedRoles={['tutor', 'admin', "student"]}
                    />
                  }
                />
                <Route
                  path="/membership"
                  element={
                    <ProtectedRoute
                      element={<MembershipOptions />}
                      allowedRoles={['tutor', 'admin', 'student']}
                    />
                  }
                />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/privacy" element={<Privacy />} />
              </Routes>
              <Footer />
            </div>
          </div>
        </AuthProvider>
      </CalendarProvider>
    </Router>
  );
};

export default App;

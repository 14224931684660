import React, { useEffect, useState } from 'react';
import './TutorInfo.css';
import { Link, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc, collection, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useCalendarContext } from '../../contexts/CalendarContext/calendarContext2';
import { moment } from 'moment-timezone';
const TutorProfileComponent = () => {
  const [tutors, setTutors] = useState(null); // Store the list of tutors
  const [error, setError] = useState(null);
  const [scheduledClasses, setScheduledClasses] = useState({});
  const [credits, setCredits] = useState({}); // Store credits for each tutor
  const [showClasses, setShowClasses] = useState({});
  const [studentEmail, setStudentEmail] = useState(null);


  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;
  const studentUID = user ? user.uid : null;
  const navigate = useNavigate(); // Crear la función navigate


  // Fetch the tutors and their information
  useEffect(() => {

    const fetchStudentEmail = async () => {
      if (!studentUID) return;

      const storedEmail = localStorage.getItem('studentEmail');
      if (!storedEmail) {
        try {
          const emailDoc = await getDoc(doc(db, 'users', studentUID));
          if (emailDoc.exists()) {
            const email = emailDoc.data().email;
            setStudentEmail(email);
            localStorage.setItem('studentEmail', email);
          } else {
            setError("Email not found for this user.");
          }
        } catch (error) {
          console.error("Error fetching email:", error);
          setError("Failed to retrieve email.");
        }
      } else {
        setStudentEmail(storedEmail);
      }
    };

    const fetchTutors = async () => {
      if (!studentUID) return;

      console.log("Fetching tutors executed.");
      const tutorRef = collection(db, "users", studentUID, "tutors");

      try {
        const tutorSnapshot = await getDocs(tutorRef);
        console.log("Tutor snapshot retrieved.");

        const tutorList = await Promise.all(
          tutorSnapshot.docs.map(async (tutorDoc) => {
            const tutorData = tutorDoc.data();
            const tutorId = tutorDoc.id;
            console.log(`Tutor ${tutorId} data retrieved.`);
            const tutorCredits = tutorData.credits || 0; // Get the credits field, default to 0 if missing

            // Fetch the specific links for each tutor from /users/tutorId
            const tutorLinksRef = doc(db, "users", tutorId); // Correct usage of doc
            const tutorLinksSnapshot = await getDoc(tutorLinksRef);
            const { googleMeet = "", zoom = "", teams = "", whereby = "" } = tutorLinksSnapshot.exists()
              ? tutorLinksSnapshot.data()
              : {};

            console.log(googleMeet, zoom, teams, whereby);
            // Set credits for each tutor dynamically
            setCredits((prev) => ({
              ...prev,
              [tutorId]: tutorCredits,
            }));

            return {
              id: tutorId,
              ...tutorData,
              googleMeet,
              zoom,
              teams,
              whereby, // Include the specific links
            };
          })
        );

        setTutors(tutorList);
        console.log(`Tutors set: ${tutorList.length} tutors loaded.`);
      } catch (error) {
        console.error("Error fetching tutors: ", error);
        setError("Error fetching tutors: " + error.message);
      }
    };

    fetchTutors();
    fetchStudentEmail();
  }, [db, studentUID]);

  // Fetch scheduled classes for each tutor only when tutors are available
  useEffect(() => {
    const fetchScheduledClasses = async () => {
      if (!studentUID || !tutors || tutors.length === 0) return;

      console.log("Fetching scheduled classes executed.");
      try {
        tutors.forEach(async (tutor) => {
          const classesRef = doc(
            db,
            "users",
            studentUID,
            "tutors",
            tutor.id,
          );
          const classSnapshot = await getDoc(classesRef);

          if (classSnapshot.exists()) {
            const classData = classSnapshot.data();
            const scheduled = classData.scheduledClasses || [];
            setScheduledClasses((prev) => ({
              ...prev,
              [tutor.id]: scheduled,
            }));
            console.log(`Scheduled classes fetched for tutor ${tutor.id}: ${scheduled.length} classes.`);
          }
        });
      } catch (error) {
        console.error("Error fetching scheduled classes: ", error);
      }
    };

    fetchScheduledClasses();
  }, [db, studentUID, tutors]);


  // Function to format the date
  const formatDate = (classItem) => {
    const { start } = classItem; // We no longer need the 'timezone' here
    const date = new Date(start);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true, // Use 12-hour format
    };

    // Format the date in the user's local timezone by not specifying the 'timeZone' option
    console.log("Date formatted for class item.");
    return new Intl.DateTimeFormat("en-US", options).format(date);
  };


  const storeTutorScheduleInLocalStorage = (tutorId, studentUid) => {
    // Save tutor UID, student UID, and tutorschedule value to local storage
    localStorage.setItem('tutorUID', tutorId);
    localStorage.setItem('studentUID', studentUid);
    localStorage.setItem('action', 'schedule');

    console.log("Data saved in local storage for scheduling: ", {
      tutorUID: tutorId,
      studentUID: studentUid,
      schedule: true,
    });
  };

  const toggleShowClasses = (tutorId) => {
    setShowClasses((prev) => ({
      ...prev,
      [tutorId]: !prev[tutorId], // Toggle visibility for this specific tutor
    }));
  };

  const sortClassesByDate = (classes) => {
    return classes.sort((a, b) => new Date(b.start) - new Date(a.start));
  };



  const isMoreThan24HoursAway = (start) => {
    const classDate = new Date(start);
    const now = new Date();
    const timeDifference = classDate.getTime() - now.getTime(); // Difference in milliseconds

    // Check if the difference is more than 24 hours (24 hours = 24 * 60 * 60 * 1000 milliseconds)
    return timeDifference > 24 * 60 * 60 * 1000;
  };



  const handleReschedule = (classItem, studentUid, tutorId) => {
    // Guardar valores en local storage, usando el campo "start" en lugar del índice
    localStorage.setItem('tutorUID', tutorId);
    localStorage.setItem('studentUID', studentUid);
    localStorage.setItem('classStart', classItem.start); // Store the "start" time
    localStorage.setItem('action', 'reschedule');

    console.log("Datos guardados en local storage: ", {
      tutorUID: tutorId,
      studentUID: studentUid,
      classStart: classItem.start, // Logging the start time instead of index
      reschedule: true,
    });
    navigate("/calendar"); // Navegar al calendario para la reprogramación
  };


  const { handleCancel } = useCalendarContext();


  const handleCancelClick = async (studentUID, classItem, tutorId, classIndex) => {
    try {
      // Call the handleCancel function from CalendarContext (assumed to handle Firestore deletion)
      await handleCancel(studentUID, classItem, tutorId);

      // Update the scheduledClasses state to remove the canceled class
      setScheduledClasses((prevScheduledClasses) => {
        const updatedClasses = prevScheduledClasses[tutorId]?.filter((_, index) => index !== classIndex);

        return {
          ...prevScheduledClasses,
          [tutorId]: updatedClasses || [], // Ensure it's always an array
        };
      });

      console.log(`Class canceled for student: ${studentUID}, tutor: ${tutorId}`);

      // After canceling the class, update credits for the tutor
      const tutorRef = doc(db, "users", studentUID, "tutors", tutorId);

      // Fetch current credits, and increment them
      const currentCredits = parseInt(credits[tutorId], 10) || 0; // Get current credits or default to 0
      const newCredits = currentCredits + 1; // Add 1 credit back after canceling the class

      // Update the credits in Firestore
      await updateDoc(tutorRef, { credits: newCredits });

      // Update the credits state to reflect the new value in the UI
      setCredits((prev) => ({
        ...prev,
        [tutorId]: newCredits,
      }));

      console.log(`Credits updated for tutor ${tutorId}: ${newCredits}`);
    } catch (error) {
      console.error("Error while canceling the class and updating credits: ", error);
    }
  };

  const handleClick = () => {
    navigate('/tutors');
  };

  if (!tutors) {
    return (null);
  }

  return (
    <div className="tutor-profiles-container">
      {tutors.length > 0 ? (
        tutors.map((tutor) => (
          <div key={tutor.id} className="tutor-profile">
            <div className="tutor-profile-content">
              <div className="tutor-profile-info">
                <img
                  src="/img/profilepic1.jpg"
                  alt={`${tutor.name}'s profile`}
                  className="tutor-profile-picture"
                />
                <h2>{tutor.name}</h2>
              </div>

              <div className="tutor-profile-credits">
                {error ? (
                  <p className="tutor-profile-error-message">{error}</p>
                ) : (
                  <p>Credits: {credits[tutor.id] !== null ? credits[tutor.id] : "Loading..."}</p>
                )}
                <div className="tutor-profile-button-group">
                  <button
                    className="tutor-profile-credits-button"
                    onClick={() => {
                      // Store tutorUID and studentUID in localStorage
                      localStorage.setItem('tutorUID', tutor.id);
                      localStorage.setItem('studentUID', studentUID);
                      localStorage.setItem('studentEmail', studentEmail);
                      // Redirect to the membership page
                      navigate('/membership');
                    }}
                  >
                    Get More Credits
                  </button>



                  <Link to={"/calendar"}>
                    {credits[tutor.id] > 0 && (
                      <button
                        className="tutor-profile-schedule-button"
                        onClick={() => storeTutorScheduleInLocalStorage(tutor.id, studentUID)}
                      >
                        Schedule Classes
                      </button>
                    )}
                  </Link>
                  <button
                    className="tutor-profile-toggle"
                    onClick={() => toggleShowClasses(tutor.id)}
                  >
                    {showClasses[tutor.id] ? 'Hide history △' : 'Class history ▽'}
                  </button>
                </div>
              </div>
              <div className="tutor-profile-contact">
                <h3>Classroom:</h3>
                <div className="tutor-profile-links">
                  {tutor.googleMeet && (
                    <a href={tutor.googleMeet} target="_blank" rel="noopener noreferrer">Google Meet</a>
                  )}
                  {tutor.skype && (
                    <a href={tutor.skype} target="_blank" rel="noopener noreferrer">Skype</a>
                  )}
                  {tutor.whereby && (
                    <a href={tutor.whereby} target="_blank" rel="noopener noreferrer">Whereby</a>
                  )}
                  {tutor.teams && (
                    <a href={tutor.teams} target="_blank" rel="noopener noreferrer">Teams</a>
                  )}
                </div>
              </div>
              {showClasses[tutor.id] && (
                <>
                  <div className="tutor-profile-classes">
                    <h3>Scheduled Classes:</h3>
                    <ul>
                      {scheduledClasses[tutor.id] && scheduledClasses[tutor.id].length > 0 ? (
                        sortClassesByDate(scheduledClasses[tutor.id]).map((classItem, index) => (
                          <li className='tutor-profile-class' key={index}>
                            {formatDate(classItem)}
                            {isMoreThan24HoursAway(classItem.start) && (
                              <>
                                <button
                                  className='reschedule-cancel'
                                  onClick={() =>
                                    handleReschedule(classItem, studentUID, tutor.id)
                                  }
                                >
                                  Reschedule
                                </button>
                                <button
                                  className='reschedule-cancel'
                                  onClick={() =>
                                    handleCancelClick(studentUID, classItem, tutor.id, index)
                                  }
                                >
                                  Cancel
                                </button>
                              </>
                            )}
                          </li>
                        ))
                      ) : (
                        <p>No scheduled classes found.</p>
                      )}
                    </ul>
                  </div>

                </>
              )}
            </div>

            {/* Gradient line at the bottom of the tutor profile */}
            <div className="gradient-line"></div>
          </div>
        ))
      ) : (
        <>
          <p>You're just one click away from starting your language learning adventure! 🚀</p>
          <br></br>
          <button className='find-your-tutor' onClick={handleClick}>Find your tutor</button>
        </>
      )}
    </div>
  );



};

export default TutorProfileComponent;


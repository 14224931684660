import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone'; // Import moment-timezone
import { useCalendarContext } from '../../contexts/CalendarContext/calendarContext2';
import '../Calendar/Calendar.css';

const TIME_SLOT_DURATION = 30;
const HOURS_START = 0;
const HOURS_END = 24;
const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


const Calendar = () => {
    const {
        isLoading,
        currentWeek,
        setCurrentWeek,
        blockedSlots,
        availableSlots,
        unavailableSlots,
        selectedSlot,
        setSelectedSlot,
        isAuthenticated,
        userData,
        error,
        accessToken,
        studentAccessToken,
        classData,
        eventId,
        studentEventId,
        users_tutor,
        users_student,
        clientId,
        clientSecret,
        refreshToken,
        studentRefreshToken,
        calendarIds,
        tutorWorkingHoursStart,
        tutorWorkingHoursEnd,
        tutorTimeZone,
        handleNextWeek,
        handlePreviousWeek,
        handleSlotClick,
        bookTrialClass,
        bookNormalClass,
        rescheduleClass,
        handleCancel,
        firestoreRoutes,
        InfoBeforeCalendarData,
        fetchBlockedSlots,
        requestAccessTokenWithRefreshToken,
        requestStudentAccessTokenWithRefreshToken,
        verifySelectedSlot,
        createClassFirestore,
        createGoogleCalendarEvent,
        updateGoogleCalendarEventIds,
        blockSelectedSlotsCalendarUI,
        verifyAlreadyTookTrial,
        CheckCredits,
        decrementCredits,
        incrementCredits,
        deleteClassFirestore,
        deleteGoogleCalendarEvent,
    } = useCalendarContext();

    // State to handle which class to apply based on screen size
    const [isMobileView, setIsMobileView] = useState(false);
    const [daysToShow, setDaysToShow] = useState(7); // Default to 7 days

    useEffect(() => {
        const handleResize = () => {
            const portraitMode = window.matchMedia("(max-width: 750px) and (orientation: portrait)").matches;
            const isMobile = portraitMode;

            setIsMobileView(isMobile);

            // Change the number of days to show based on screen size
            if (isMobile) {
                setDaysToShow(4); // On smaller screens, show 4 days instead of 7
            } else {
                setDaysToShow(7); // On larger screens, show 7 days
            }
        };

        // Check the screen size on initial load
        handleResize();

        // Add event listener for screen resize
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const weekRange = `${moment(currentWeek).format('MMM DD')} – ${moment(currentWeek).add(daysToShow - 1, 'days').format('MMM DD, YYYY')}`;
    const handleKindOfBooking = () => {
        const action = localStorage.getItem('action');
        if (selectedSlot) {
            const utcStart = selectedSlot.start.clone().utc();
            const utcEnd = selectedSlot.end.clone().utc();

            switch (action) {
                case 'schedule':
                    bookNormalClass({ start: utcStart, end: utcEnd });
                    break;
                case 'reschedule':
                    rescheduleClass({ start: utcStart, end: utcEnd });
                    break;
                case 'booktrial':
                    bookTrialClass({ start: utcStart, end: utcEnd });
                    break;
                default:
                    console.error('Invalid action');
            }

            setSelectedSlot(null);
        }
    };

    // Asegúrate de definir 'action' antes de usarla
    const action = localStorage.getItem('action'); // O ajusta a cómo quieras obtener esta variable

    const actionButtonText = action === 'schedule' ? 'Confirm Booking' :
        action === 'reschedule' ? 'Confirm Reschedule' :
            action === 'booktrial' ? 'Confirm Trial' : 'Confirm';


    return (
        <div className={'wholecalendar'}>
            <div className="squarecalendar"></div>
            <div className="week-navigation">
                <button className='previous-next' onClick={handlePreviousWeek}>Previous {daysToShow} Days</button>
                <h3 className="week-range">{weekRange}</h3>
                <button className='previous-next' onClick={handleNextWeek}>Next {daysToShow} Days</button>
            </div>
            <div>
                {isLoading ? (
                    <div className="loading-screen">
                        <div className="loader"></div>
                        <p>Loading Calendar...</p>
                    </div>
                ) : (
                    <div className="calendar">
                        {Array.from({ length: daysToShow }).map((_, dayIndex) => (
                            <div className="day-column" key={dayIndex}>
                                <h4 className="day-name">
                                    {moment(currentWeek).add(dayIndex, 'days').format('dddd')}
                                    {` ${moment(currentWeek).add(dayIndex, 'days').format('D')}`}
                                </h4>

                                {Array.from({ length: (HOURS_END - HOURS_START) * 2 }).map((_, slotIndex) => {
                                    const hour = HOURS_START + Math.floor(slotIndex / 2);
                                    const minute = (slotIndex % 2) * 30;

                                    const slotStart = moment(currentWeek)
                                        .add(dayIndex, 'days')
                                        .set({ hour, minute })
                                        .tz(userTimeZone); // Convert to user's timezone
                                    const slotEnd = moment(slotStart).add(TIME_SLOT_DURATION, 'minutes');

                                    const isBlocked = blockedSlots.some(slot =>
                                        moment(slot.start).isBefore(slotEnd) && moment(slot.end).isAfter(slotStart)
                                    );

                                    const isAvailable = availableSlots.some(slot => moment(slot.start).isSame(slotStart));

                                    const isUnavailable = unavailableSlots.some(slot =>
                                        moment(slot.start).isBefore(slotEnd) && moment(slot.end).isAfter(slotStart)
                                    );

                                    const nextSlotStart = moment(slotStart).add(30, 'minutes');
                                    const nextSlotEnd = moment(nextSlotStart).add(TIME_SLOT_DURATION, 'minutes');

                                    const isNextBlocked = blockedSlots.some(slot =>
                                        moment(slot.start).isBefore(nextSlotEnd) && moment(slot.end).isAfter(nextSlotStart)
                                    );

                                    const isSelected = selectedSlot && moment(selectedSlot.start).isSame(slotStart);
                                    const isNextSelected = selectedSlot && moment(selectedSlot.start).add(30, 'minutes').isSame(slotStart);

                                    const canSelect = isAvailable && !isNextBlocked && !isBlocked && !isUnavailable;

                                    let slotClass = '';
                                    if (isUnavailable) {
                                        slotClass = 'unavailable';
                                    } else if (isBlocked) {
                                        slotClass = 'blocked';
                                    } else if (isAvailable) {
                                        slotClass = 'available';
                                    }

                                    return (
                                        <div
                                            key={slotIndex}
                                            className={`time-slot ${slotClass} ${isSelected || isNextSelected ? 'selected' : ''}`}
                                            onClick={canSelect ? () => handleSlotClick({ start: slotStart, end: slotEnd }) : null}
                                        >
                                            {slotStart.format('h:mm A')}
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {selectedSlot && (
                <div className="booking-confirmation">
                    <p>
                        Your 55 minute class will start at:<br /> <b>{selectedSlot.start.format('h:mm A')}</b>
                    </p>
                    <button onClick={handleKindOfBooking}>{actionButtonText}</button>
                    <button onClick={() => setSelectedSlot(null)}>Cancel</button>
                </div>
            )}
        </div>
    );
};

export default Calendar;

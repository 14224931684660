import { createContext, useContext, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { auth } from '../../firebase/firebase';
import { onAuthStateChanged } from "firebase/auth";
import { increment, getFirestore, doc, getDoc, setDoc, arrayUnion, updateDoc, collection, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import { set } from 'date-fns';
const db = getFirestore();
const CalendarContext = createContext();
export const useCalendarContext = () => useContext(CalendarContext);
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const CalendarProvider = ({ children }) => {
    const navigate = useNavigate();

    const TIME_SLOT_DURATION = 30;
    const HOURS_START = 0;
    const HOURS_END = 24;
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [currentWeek, setCurrentWeek] = useState(moment().startOf('week').add(1, 'days'));
    const [blockedSlots, setBlockedSlots] = useState([]);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [unavailableSlots, setUnavailableSlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [studentAccessToken, setStudentAccessToken] = useState(null);
    const [classData, setClassData] = useState(null);
    const [tutorEventId, setTutorEventId] = useState(null);
    const [eventId, setEventId] = useState(null);
    const [studentEventId, setStudentEventId] = useState(null);


    //FIRESTORE INFO AND ROUTES
    const [users_tutor, setUsers_tutor] = useState({});
    const [users_student, setUsers_student] = useState({});
    const [users_tutor_students_student, setUsers_tutor_students_student] = useState({});
    const [users_student_tutors_tutor, setUsers_student_tutors_tutor] = useState({});
    const [users_tutor_students, setUsers_tutor_students] = useState({});
    const [clientId, setClientId] = useState(''); //POR EL MOMENTO EN FIRESTORE
    const [clientSecret, setClientSecret] = useState(''); //POR EL MOMENTO EN FIRESTORE
    const [refreshToken, setRefreshToken] = useState(''); //POR EL MOMENTO EN FIRESTORE (Quizas ahí queda) 
    const [studentRefreshToken, setStudentRefreshToken] = useState('');
    const [calendarIds, setCalendarIds] = useState([]);
    const [tutorWorkingHoursStart, setTutorWorkingHoursStart] = useState('');
    const [tutorWorkingHoursEnd, setTutorWorkingHoursEnd] = useState('');
    const [tutorTimeZone, setTutorTimeZone] = useState('');
    const tutorUID = localStorage.getItem('tutorUID');
    const studentUID = localStorage.getItem('studentUID');



    const firestoreRoutes = async () => {
        try {
            console.log("Fetching Firestore documents...");

            // Get UIDs from localStorage
            const tutorUID = localStorage.getItem('tutorUID');
            const studentUID = localStorage.getItem('studentUID');

            if (!tutorUID || !studentUID) {
                throw new Error('tutorUID or studentUID not found in localStorage');
            }

            // Fetch all documents in parallel for better performance
            const [
                tutorDoc,
                studentDoc,
                tutorStudentDoc,
                studentTutorDoc,
                tutorStudentsSnapshot
            ] = await Promise.all([
                getDoc(doc(db, 'users', tutorUID)),
                getDoc(doc(db, 'users', studentUID)),
                getDoc(doc(db, 'users', tutorUID, 'students', studentUID)),
                getDoc(doc(db, 'users', studentUID, 'tutors', tutorUID)),
                getDocs(collection(db, `users/${tutorUID}/students`))
            ]);

            // Set tutor data if exists
            if (tutorDoc.exists()) {
                const tutorData = tutorDoc.data();
                console.log("Tutor data fetched:", tutorData);
                setUsers_tutor(tutorData);
            } else {
                console.warn('Tutor document not found');
                setUsers_tutor({});
            }

            // Set student data if exists
            if (studentDoc.exists()) {
                const studentData = studentDoc.data();
                console.log("Student data fetched:", studentData);
                setUsers_student(studentData);
            } else {
                console.warn('Student document not found');
                setUsers_student({});
            }

            // Set tutor-student relationship data if exists
            if (tutorStudentDoc.exists()) {
                console.log("Tutor-student data fetched:", tutorStudentDoc.data());
                setUsers_tutor_students_student(tutorStudentDoc.data());
            } else {
                console.warn('Tutor-student document not found');
                setUsers_tutor_students_student({});
            }

            // Set student-tutor relationship data if exists
            if (studentTutorDoc.exists()) {
                console.log("Student-tutor data fetched:", studentTutorDoc.data());
                setUsers_student_tutors_tutor(studentTutorDoc.data());
            } else {
                console.warn('Student-tutor document not found');
                setUsers_student_tutors_tutor({});
            }

            // Set tutor's students data if exists
            if (!tutorStudentsSnapshot.empty) {
                const studentsData = tutorStudentsSnapshot.docs.map(doc => doc.data());
                console.log("Tutor's students data fetched:", studentsData);
                setUsers_tutor_students(studentsData);
            } else {
                console.warn('No students found for tutor');
                setUsers_tutor_students([]);
            }

            return true;
        } catch (error) {
            console.error('Error in firestoreRoutes:', error);
            setError(error.message);
            return false;
        }
    };


    const InfoBeforeCalendarData = async () => {
        try {
            // Wait for users_tutor to be populated
            if (!users_tutor || Object.keys(users_tutor).length === 0) {
                console.warn('users_tutor data not available');
                return false;
            }

            // Destructure required fields with default values
            const {
                CLIENT_ID = '',
                CLIENT_SECRET = '',
                REFRESH_TOKEN = '',
                calendarIds = [],
                tutorWorkingHoursStart = 0,
                tutorWorkingHoursEnd = 24,
                timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            } = users_tutor;

            // Validate required fields
            if (!CLIENT_ID || !CLIENT_SECRET || !REFRESH_TOKEN) {
                throw new Error('Missing required calendar credentials');
            }

            // Set all calendar-related states
            setClientId(CLIENT_ID);
            setClientSecret(CLIENT_SECRET);
            setRefreshToken(REFRESH_TOKEN);
            setCalendarIds(calendarIds);
            setTutorWorkingHoursStart(tutorWorkingHoursStart);
            setTutorWorkingHoursEnd(tutorWorkingHoursEnd);
            setTutorTimeZone(timezone);

            console.log('Calendar configuration loaded successfully');
            return true;
        } catch (error) {
            console.error('Error in InfoBeforeCalendarData:', error);
            setError(error.message);
            return false;
        }
    };



    const requestAccessTokenWithRefreshToken = async () => {
        try {
            console.log("Client ID (Tutor):", clientId);
            console.log("Client Secret (Tutor):", clientSecret);
            console.log("Tutor Refresh Token:", refreshToken);

            if (!clientId || !clientSecret || !refreshToken) {
                console.error('Missing required credentials for tutor token request');
                return;
            }

            const tokenUrl = 'https://oauth2.googleapis.com/token';
            const body = new URLSearchParams({
                client_id: clientId,
                client_secret: clientSecret,
                refresh_token: refreshToken,
                grant_type: 'refresh_token',
            });

            const response = await fetch(tokenUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body.toString(),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to obtain tutor access token');
            }

            setAccessToken(data.access_token);
            console.log('Tutor access token obtained successfully:', data.access_token);
            return data.access_token;
        } catch (error) {
            console.error('Error obtaining tutor access token:', error);
        }
    };

    const requestStudentAccessTokenWithRefreshToken = async () => {
        try {
            console.log("Client ID:", clientId);
            console.log("Client Secret:", clientSecret);
            console.log("Student Refresh Token:", users_student.REFRESH_TOKEN);

            // Validate required student refresh token
            if (!clientId || !clientSecret || !users_student.REFRESH_TOKEN) {
                console.error('Missing required credentials for student token request');
                return;
            }

            // Proceed with token request
            const tokenUrl = 'https://oauth2.googleapis.com/token';
            const body = new URLSearchParams({
                client_id: clientId,
                client_secret: clientSecret,
                refresh_token: users_student.REFRESH_TOKEN,
                grant_type: 'refresh_token',
            });

            const response = await fetch(tokenUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body.toString(),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Failed to obtain student access token');
            }

            setStudentAccessToken(data.access_token);
            console.log('Student access token obtained successfully:', data.access_token);
            return data.access_token;
        } catch (error) {
            console.error('Error obtaining student access token:', error);
        }
    };




    const fetchBlockedSlots = async (accessToken) => {
        const startOfWeek = moment(currentWeek).startOf('isoWeek').tz(userTimeZone).toISOString();
        const endOfWeek = moment(currentWeek).endOf('isoWeek').tz(userTimeZone).toISOString();

        let allBlockedSlots = [];

        try {
            const fetchPromises = calendarIds.map(async (calendarId) => {
                const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events?timeMin=${startOfWeek}&timeMax=${endOfWeek}&singleEvents=true&orderBy=startTime`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();
                const blockedSlots = data.items.map(event => ({
                    start: moment(event.start.dateTime || event.start.date).tz(userTimeZone).format(),
                    end: moment(event.end.dateTime || event.end.date).tz(userTimeZone).format(),
                }));
                return blockedSlots;
            });

            allBlockedSlots = await Promise.all(fetchPromises);
            allBlockedSlots = [].concat(...allBlockedSlots); // Flatten the array
        } catch (error) {
            console.error("Error fetching blocked slots:", error);
        }
        console.log(allBlockedSlots)
        return allBlockedSlots;
    };


    const fetchData = async () => {
        try {
            console.log('Using access token:', accessToken);

            if (!accessToken) {
                throw new Error('Access token not available');
            }

            if (!tutorTimeZone || tutorWorkingHoursStart === undefined || tutorWorkingHoursEnd === undefined) {
                throw new Error('Tutor configuration not properly loaded');
            }

            // Fetch blocked slots
            const newBlockedSlots = await fetchBlockedSlots(accessToken);
            const newAvailableSlots = [];
            const newUnavailableSlots = [];

            // Get scheduled classes from all students
            let scheduledClasses = [];
            if (Array.isArray(users_tutor_students)) {
                scheduledClasses = users_tutor_students.flatMap(student =>
                    student.scheduledClasses || []
                );
            }

            // Generate time slots
            for (let day = 0; day < 7; day++) {
                for (let hour = HOURS_START; hour < HOURS_END; hour++) {
                    for (let minute = 0; minute < 60; minute += TIME_SLOT_DURATION) {
                        const slotStart = moment(currentWeek)
                            .add(day, 'days')
                            .set({ hour, minute });
                        const slotEnd = moment(slotStart)
                            .add(TIME_SLOT_DURATION, 'minutes');

                        // Convert to tutor's timezone for working hours check
                        const tutorDayStart = moment(slotStart)
                            .tz(tutorTimeZone)
                            .set('hour', tutorWorkingHoursStart)
                            .set('minute', 0);
                        const tutorDayEnd = moment(slotStart)
                            .tz(tutorTimeZone)
                            .set('hour', tutorWorkingHoursEnd)
                            .set('minute', 0);

                        // Check various conditions
                        const isOutsideWorkingHours = slotStart.isBefore(tutorDayStart) ||
                            slotEnd.isAfter(tutorDayEnd);

                        const isBlocked = newBlockedSlots.some(slot =>
                            moment(slot.start).isBefore(slotEnd) &&
                            moment(slot.end).isAfter(slotStart)
                        );

                        const isScheduledClass = scheduledClasses.some(classSlot =>
                            moment(classSlot.start).isBefore(slotEnd) &&
                            moment(classSlot.end).isAfter(slotStart)
                        );

                        // Categorize the slot
                        const timeSlot = {
                            start: slotStart.format(),
                            end: slotEnd.format()
                        };

                        if (isOutsideWorkingHours) {
                            newUnavailableSlots.push(timeSlot);
                        } else if (isBlocked || isScheduledClass) {
                            newBlockedSlots.push(timeSlot);
                        } else {
                            newAvailableSlots.push(timeSlot);
                        }
                    }
                }
            }

            // Update states
            setBlockedSlots(newBlockedSlots);
            setAvailableSlots(newAvailableSlots);
            setUnavailableSlots(newUnavailableSlots);

            console.log('Calendar slots generated successfully');
            return true;
        } catch (error) {
            console.error('Error in fetchData:', error);
            setError(error.message);
            return false;
        }
    };

    useEffect(() => {
        const getNeccesaryInfo = async () => {
            setIsLoading(true);

            // First, fetch the Firestore routes
            const firestoreResult = await firestoreRoutes();
            if (!firestoreResult) {
                console.error('Failed to load Firestore data');
                setIsLoading(false);
                return;
            }

            console.log("FIRESTORE ROUTES LOADED");
            console.log("users_tutor after Firestore routes:", users_tutor);
            console.log("users_student after Firestore routes:", users_student);
        };

        getNeccesaryInfo();
    }, []);

    useEffect(() => {
        const loadCalendarData = async () => {
            setIsLoading(true);
            try {
                // Ensure tutor data is available
                if (!users_tutor || Object.keys(users_tutor).length === 0) {
                    console.warn('users_tutor data not available or empty:', users_tutor);
                    setIsLoading(false);  // Stop loading if no data
                    return;
                }

                // Load calendar configuration
                const infoResult = await InfoBeforeCalendarData();
                if (!infoResult) {
                    console.error('Failed to load calendar data');
                    return;
                }

                // Obtain tutor access token
                const tutorAccessToken = await requestAccessTokenWithRefreshToken();
                if (!tutorAccessToken) {
                    console.error('Failed to load tutor access token');
                    return;
                }

                // Set the access token once obtained
                setAccessToken(tutorAccessToken);
                console.log('Tutor Access Token:', tutorAccessToken);
            } catch (error) {
                console.error('Error loading calendar data:', error);
            } finally {
                setIsLoading(false);  // Stop loading after completion
            }
        };

        // Run when users_tutor has been populated
        if (users_tutor && Object.keys(users_tutor).length > 0) {
            loadCalendarData();  // Call only when data is available
        } else {
            console.log('Waiting for users_tutor to be populated...');
            setTimeout(() => loadCalendarData(), 2000);
        }
    }, [users_tutor, currentWeek]);  // Dependency on users_tutor

    useEffect(() => {
        setIsLoading(true);
        fetchData();
        setIsLoading(false);
    }, [users_tutor, currentWeek]);

    const handleNextWeek = () => {
        setCurrentWeek(moment(currentWeek).add(7, 'days'));
    };

    const handlePreviousWeek = () => {
        setCurrentWeek(moment(currentWeek).subtract(7, 'days'));
    };

    const handleSlotClick = (slot) => {
        if (availableSlots.some(s => moment(s.start).isSame(slot.start))) {
            setSelectedSlot(slot);
        }
    };
    /*FORMULAS FOR BOOKING TRIAL, NORMAL CLASSES AND RESCHEDULING*/

    /* USED BY ALL */
    const verifySelectedSlot = () => {
        if (!selectedSlot) {
            alert('Please select a valid time slot.');
            return false;
        } else {
            const endSlot = moment(selectedSlot.start).add(1, 'hours');
            console.log("End slot: ", endSlot.format()); // Verifica el valor de endSlot
            const isWithinValidRange = endSlot.isBefore(
                moment(currentWeek).add(7, 'days').set({ hour: HOURS_END })
            );
            console.log("Is within valid range:", isWithinValidRange); // Verifica si está dentro del rango
            return isWithinValidRange;
        }
    };


    useEffect(() => {
        if (selectedSlot) {
            setClassData({
                day: selectedSlot.start.format('dddd'),
                hour: selectedSlot.start.format('HH:mm'),
                timezone: userTimeZone,
                cost: users_student_tutors_tutor.cost, // Accessing `cost` directly from the state
                start: selectedSlot.start.toISOString(),
                end: moment(selectedSlot.start).add(1, 'hours').toISOString(), // Assuming this is endSlot
                tutorEventId: `${tutorUID + selectedSlot.start.format("ddddHH:mm")}`,
                studentEventId: `${studentUID + selectedSlot.start.format("ddddHH:mm")}`
            });
        }
    }, [selectedSlot, users_student_tutors_tutor]); // Make sure users_student_tutors_tutor is updated

    const createFirestorePathForTrialClass = async () => {
        // Asegúrate de que studentUID y tutorUID tengan los valores correctos
        if (!studentUID || !tutorUID) {
            throw new Error("studentUID o tutorUID no están definidos");
        }
    
        // Crea la ruta en Firestore para el estudiante con datos iniciales
        const studentDocRef = doc(db, 'users', studentUID, 'tutors', tutorUID);
        await setDoc(studentDocRef, {
            credits: 0,
            cost: 0,
            scheduledClasses: []
        }, { merge: true });
        console.log("Ruta creada en Firestore para el estudiante.");
    
        // Crea la ruta en Firestore para el tutor con datos iniciales
        const tutorDocRef = doc(db, 'users', tutorUID, 'students', studentUID);
        await setDoc(tutorDocRef, {
            credits: 0,
            cost: 0,
            scheduledClasses: []
        }, { merge: true });
        console.log("Ruta creada en Firestore para el tutor.");
    };
    

    const createClassFirestore = async () => {

        // Actualizar Firestore para el tutor
        await updateDoc(doc(db, "users", studentUID, "tutors", tutorUID), {
            scheduledClasses: arrayUnion(classData),
        });

        // Actualizar Firestore para el estudiante en la ruta del tutor
        updateDoc(doc(db, "users", tutorUID, "students", studentUID), {
            scheduledClasses: arrayUnion(classData)
        });
    };

    const createGoogleCalendarEvent = async () => {
        try {
            const studentName = users_student.name || "Unknown Student";
            console.log("Student Name:", studentName);

            // Definir `endSlot` basado en el `selectedSlot`
            const endSlot = moment(selectedSlot.start).add(1, 'hours'); // Añadir 1 hora al horario seleccionado

            const event = {
                summary: `${studentName ? studentName : 'UserERROR'} LearnLantern Class`,
                start: {
                    dateTime: selectedSlot.start.format(),
                    timeZone: userTimeZone,
                },
                end: {
                    dateTime: endSlot.format(), // Usar `endSlot` aquí
                    timeZone: userTimeZone,
                },
            };

            // Crear el evento para el tutor
            const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/primary/events`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`, // Token de acceso del tutor
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(event),
            });

            if (response.ok) {
                const eventData = await response.json();
                const eventId = eventData.id;
                setTutorEventId(eventId); // Guarda el ID del evento del tutor en el estado
                console.log("Google Calendar event created successfully for the tutor. Event ID:", eventId);

                // Crear el evento en el calendario del estudiante
                let studentAccessToken = localStorage.getItem('google_student_access_token');
                if (!studentAccessToken) {
                    studentAccessToken = await requestStudentAccessTokenWithRefreshToken(studentUID);
                }

                const studentCalendarResponse = await fetch(`https://www.googleapis.com/calendar/v3/calendars/primary/events`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${studentAccessToken}`, // Token de acceso del estudiante
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(event),
                });

                if (studentCalendarResponse.ok) {
                    const studentEventData = await studentCalendarResponse.json();
                    const studentEventId = studentEventData.id;
                    setStudentEventId(studentEventId); // Guarda el ID del evento del estudiante en el estado
                    console.log("Google Calendar event created successfully for the student. Event ID:", studentEventId);
                } else {
                    console.warn("Failed to book the event in Google Calendar for the student.");
                }
            } else {
                console.warn("Failed to book the event in Google Calendar for the tutor.");
            }
        } catch (error) {
            console.error("Error creating Google Calendar event:", error);
        }
    };

    const updateGoogleCalendarEventIds = async () => {
        try {
            console.log("Verificando scheduledClasses del tutor y estudiante...");
            console.log("Tutor Event ID:", tutorEventId);
            console.log("Student Event ID:", studentEventId);

            // Actualizamos el documento del estudiante
            const studentRef = doc(db, "users", studentUID, "tutors", tutorUID);
            const studentSnapshot = await getDoc(studentRef);
            const studentData = studentSnapshot.data();
            let studentScheduledClasses = studentData.scheduledClasses || [];

            // Actualizamos el eventId y studentEventId en la clase correcta
            studentScheduledClasses = studentScheduledClasses.map(cls => {
                if (cls.start === classData.start) {
                    console.log("Actualizando eventId ESTUDIANTE y studentEventId para la clase del estudiante:", cls + " -> " + eventId + " -> " + studentEventId);
                    return {
                        ...cls,
                        tutorEventId: tutorEventId, // Actualizar el eventId
                        studentEventId: studentEventId // Actualizar studentEventId
                    };
                }
                return cls
            });

            // Actualizamos Firestore con las clases modificadas
            console.log("Actualizando Firestore para el estudiante con clases actualizadas:", studentScheduledClasses);
            await updateDoc(studentRef, {
                scheduledClasses: studentScheduledClasses
            });
            console.log("Event ID actualizado exitosamente para el estudiante.");

            // Ahora hacemos lo mismo para el documento del tutor
            const tutorRef = doc(db, "users", tutorUID, "students", studentUID);
            const tutorSnapshot = await getDoc(tutorRef);
            const tutorData = tutorSnapshot.data();
            let tutorScheduledClasses = tutorData.scheduledClasses || [];

            // Actualizamos el eventId y studentEventId en la clase correcta
            tutorScheduledClasses = tutorScheduledClasses.map(cls => {
                if (cls.start === classData.start) {
                    console.log("Actualizando eventId TUTOR y studentEventId para la clase del tutor:", cls + " -> " + eventId + " -> " + studentEventId);
                    return {
                        ...cls,
                        tutorEventId: tutorEventId, // Actualizar el eventId 
                        studentEventId: studentEventId // Actualizar studentEventId
                    };
                }
                return cls;
            });

            // Actualizamos Firestore con las clases modificadas
            console.log("Actualizando Firestore para el tutor con clases actualizadas:", tutorScheduledClasses);
            await updateDoc(tutorRef, {
                scheduledClasses: tutorScheduledClasses
            });
            console.log("Event ID actualizado exitosamente para el tutor.");

        } catch (error) {
            console.error("Error actualizando los documentos de Firestore: ", error);
        }
    };



    const blockSelectedSlotsCalendarUI = () => {
        try {
            const endSlot = moment(selectedSlot.start).add(1, 'hours'); // Añadir 1 hora al horario seleccionado
            console.log("Selected slot:", selectedSlot.start.format());

            const newBlockedSlots = [
                ...blockedSlots,
                { start: selectedSlot.start.format(), end: endSlot.format() }
            ];

            setBlockedSlots(newBlockedSlots);
            setSelectedSlot(null);

            updateDoc(doc(db, "users", studentUID), { alreadytooktrial: true });
        } catch (error) {
            console.error('Error during booking process:', error);
        }
    };


    /* USED BY TRIAL */

    const verifyAlreadyTookTrial = async () => {
        const studentRef = doc(db, "users", studentUID);
        const studentSnapshot = await getDoc(studentRef);
        if (studentSnapshot.exists()) {
            const studentData = studentSnapshot.data();
            if (studentData.alreadytooktrial) {
                alert("You have already taken the trial.");
                return;
            } else {
                console.log("Student has not taken the trial yet.");
            }
        }
    };

    const createTutorDoc = () => {
        // Obtener el nombre del tutor
        tutorData = users_tutor.data();
        const tutorName = tutorData.name;
        const tutorPrice = tutorData.price;

        setDoc(users_student_tutors_tutor, {
            credits: 0,
            name: tutorName,
            tutor: tutorUID,
            cost: tutorPrice
        }, { merge: true });
        console.log("Student Firestore updated successfully with the new trial class.");
    }

    /* USED BY BOOKING */

    const CheckCredits = async () => {

        // Check credits before proceeding
        let currentCredits = 0; // Initialize the currentCredits variable

        currentCredits = users_student_tutors_tutor.credits || 0; // Safely assign credits
        console.log("Current credits:", currentCredits);
        if (currentCredits <= 0) {
            console.warn("No credits available to decrement.");
            alert("This student does not have enough credits to book the class.");
            return; // Stop the process if no credits are available
        }
    };

    const decrementCredits = async () => {
        // Obtener el valor actual de los créditos desde `users_student_tutors_tutor`
        let currentCredits = users_student_tutors_tutor.credits || 0; // Asegúrate de que `credits` exista

        if (currentCredits <= 0) {
            console.warn("No credits available to decrement.");
            alert("Este estudiante no tiene suficientes créditos para reservar la clase.");
            return; // Detén el proceso si no hay créditos disponibles
        }

        // Decrementar el crédito en 1
        await updateDoc(doc(db, "users", studentUID, "tutors", tutorUID), {
            credits: currentCredits - 1,
        });
        console.log("Crédito decrementado en 1.");
    };


    /*USED BY RESCHEDULING AND CANCELLING*/


    const incrementCredits = async () => {
        // Obtener el valor actual de los créditos desde `users_student_tutors_tutor`
        let currentCredits = users_student_tutors_tutor.credits || 0; // Asegúrate de que `credits` exista

        // Decrementar el crédito en 1
        await updateDoc(doc(db, "users", studentUID, "tutors", tutorUID), {
            credits: currentCredits + 1,
        });
        console.log("Crédito aumentado en 1.");
    };


    const deleteClassFirestore = async () => {
        const start = localStorage.getItem('classStart'); // Obtener el start desde el localStorage
        if (start !== null) {
            try {
                console.log(`Eliminando la clase con hora de inicio ${start} de Firestore.`);
                const tutorScheduledClasses = users_tutor_students_student.scheduledClasses || [];
                const studentScheduledClasses = users_student_tutors_tutor.scheduledClasses || [];

                // Filtrar las clases basadas en el start para eliminarlas
                const filteredTutorClasses = tutorScheduledClasses.filter(
                    (classObj) => classObj.start !== start
                );

                const filteredStudentClasses = studentScheduledClasses.filter(
                    (classObj) => classObj.start !== start
                );

                // Actualizar los documentos en Firestore eliminando la clase
                await updateDoc(tutorStudentDocRef, { scheduledClasses: filteredTutorClasses });
                await updateDoc(studentClassDocRef, { scheduledClasses: filteredStudentClasses });

                console.log(`Clase con hora de inicio ${start} eliminada exitosamente de Firestore para tutor y estudiante.`);

                // Eliminar el evento de Google Calendar si tiene un eventId asociado
                const classToRemove = studentScheduledClasses.find(classObj => classObj.start === start);
                if (classToRemove && classToRemove.eventId) {
                    await deleteGoogleCalendarEvent(classToRemove.eventId); // Llamada para eliminar el evento de Google Calendar
                }

            } finally {
                // Limpiar el localStorage después de eliminar
                localStorage.removeItem('classStart');
            }
        } else {
            console.log("No se encontró ningún valor de 'classStart' en localStorage.");
        }
    };

    const deleteGoogleCalendarEvent = async (tutorEventId, studentEventId) => {
        try {
            // Obtener el evento correcto del tutor y estudiante basado en la fecha

            // Eliminar evento del calendario del tutor
            const deleteTutorEventResponse = await fetch(`https://www.googleapis.com/calendar/v3/calendars/primary/events/${tutorEventId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`, // Token de acceso del tutor
                },
            });

            if (deleteTutorEventResponse.ok) {
                console.log("Evento de Google Calendar eliminado correctamente para el tutor.");

                // Eliminar evento del calendario del estudiante
                let studentAccessToken = localStorage.getItem('google_student_access_token');
                if (!studentAccessToken) {
                    studentAccessToken = await requestStudentAccessTokenWithRefreshToken(studentUID);
                }

                const deleteStudentEventResponse = await fetch(`https://www.googleapis.com/calendar/v3/calendars/primary/events/${studentEventId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${studentAccessToken}`, // Token de acceso del estudiante
                    },
                });

                if (deleteStudentEventResponse.ok) {
                    console.log("Evento de Google Calendar eliminado correctamente para el estudiante.");
                } else {
                    console.warn("Fallo al eliminar el evento de Google Calendar para el estudiante.");
                }
            } else {
                console.warn("Fallo al eliminar el evento de Google Calendar para el tutor.");
            }
        } catch (error) {
            console.error("Error al eliminar el evento de Google Calendar:", error);
        }
    };


    const bookTrialClass = async () => {
        try {
            // 1. Verificar si el estudiante ya tomó la prueba
            await verifyAlreadyTookTrial();
            console.log("Verificación de prueba completada.");

            // 2. Verificar el horario seleccionado
            const validSlot = verifySelectedSlot();
            if (!validSlot) {
                console.log("El horario seleccionado no es válido.");
                return; // Detener el proceso si el horario no es válido
            }
            console.log("Horario seleccionado verificado.");

            // 3. Agregar ruta
            await createFirestorePathForTrialClass();
            console.log("Ruta creada en Firestore.");

            // 4. Agregar clase a firestore
            await createClassFirestore();
            console.log("Clase agregada correctamente en Firestore.");

            // 5. Crear los eventos en Google Calendar
            await createGoogleCalendarEvent();
            console.log("Eventos creados en Google Calendar.");

            // 6. Actualizar los event IDs en Firestore
            await updateGoogleCalendarEventIds();
            console.log("IDs de eventos actualizados correctamente en Firestore.");

            // 7. Bloquear los slots seleccionados en la UI
            blockSelectedSlotsCalendarUI();
            console.log("Slots bloqueados en la UI correctamente.");

            alert('La clase de prueba ha sido reservada exitosamente.');
        } catch (error) {
            console.error('Error durante el proceso de reserva de clase de prueba:', error);
            alert('Ocurrió un error al reservar la clase de prueba. Por favor, intenta de nuevo.');
        }
    };


    const bookNormalClass = async () => {
        try {
            // 1. Comprobar si el estudiante tiene suficientes créditos
            await CheckCredits();
            console.log("Créditos verificados, el estudiante tiene créditos suficientes.");

            // 2. Verificar el horario seleccionado
            const validSlot = verifySelectedSlot();
            if (!validSlot) {
                console.log("El horario seleccionado no es válido.");
                return; // Detener el proceso si el horario no es válido
            }
            console.log("Horario seleccionado verificado.");

            // 3. Modificar Firestore agregando la clase
            await createClassFirestore();
            console.log("Clase agregada correctamente en Firestore.");

            // 4. Restar créditos
            await decrementCredits();
            console.log("Créditos restados correctamente.");

            // 5. Crear el evento en Google Calendar
            await createGoogleCalendarEvent();
            console.log("Evento creado en Google Calendar.");

            // 6. Actualizar los event IDs en Firestore
            await updateGoogleCalendarEventIds();
            console.log("IDs de eventos actualizados correctamente en Firestore.");

            // 7. Bloquear los slots seleccionados en la UI
            blockSelectedSlotsCalendarUI();
            console.log("Slots bloqueados en la UI correctamente.");

            alert('La clase ha sido reservada exitosamente.');
        } catch (error) {
            console.error('Error durante el proceso de reserva de clase normal:', error);
            alert('Ocurrió un error al reservar la clase. Por favor, intenta de nuevo.');
        }
    };

    const rescheduleClass = async () => {
        try {
            // 1. Verificar el horario seleccionado
            const validSlot = verifySelectedSlot();
            if (!validSlot) {
                console.log("El horario seleccionado no es válido.");
                return; // Detener el proceso si el horario no es válido
            }
            console.log("Horario seleccionado verificado.");

            // 2. Agregar la nueva clase en Firestore
            await createClassFirestore();
            console.log("Nueva clase agregada en Firestore.");

            // 3. Crear el nuevo evento en Google Calendar
            await createGoogleCalendarEvent();
            console.log("Nuevo evento creado en Google Calendar.");

            // 4. Eliminar la clase previa en Firestore
            await deletePreviousClassFirestore();
            console.log("Clase previa eliminada de Firestore.");

            // 5. Eliminar el evento previo en Google Calendar
            await deleteGoogleCalendarEvent();
            console.log("Evento previo eliminado de Google Calendar.");

            // 6. Bloquear los nuevos slots en la UI
            blockSelectedSlotsCalendarUI();
            console.log("Nuevos slots bloqueados en la UI correctamente.");

            alert('La clase ha sido reprogramada exitosamente.');
        } catch (error) {
            console.error('Error durante el proceso de reprogramación:', error);
            alert('Ocurrió un error al reprogramar la clase. Por favor, intenta de nuevo.');
        }
    };


    const handleCancel = async (studentUID, classItem, tutorUID) => {
        try {
            console.log("1. Start class cancellation process");

            // Eliminar la clase del estudiante en Firestore
            const classRef = doc(db, "users", studentUID, "tutors", tutorUID);
            const classSnapshot = await getDoc(classRef);
            const classData = classSnapshot.data();
            const classIndex = classData.scheduledClasses.findIndex(item => item.start === classItem.start);
            console.log("2. Fetched class data from Firestore");

            if (classIndex === -1) {
                throw new Error('Class not found');
            }
            console.log("3. Class found in Firestore");

            const eventId = classData.scheduledClasses[classIndex].eventId; // Extraer el eventId

            // Remover la clase del estudiante en Firestore
            const updatedClasses = classData.scheduledClasses.filter((item) => item.start !== classItem.start);
            await updateDoc(classRef, { scheduledClasses: updatedClasses });
            console.log(`4. Class canceled for student ${studentUID} and tutor ${tutorUID}`);

            // Ahora también eliminar la clase del tutor en Firestore
            const tutorClassRef = doc(db, "users", tutorUID, "students", studentUID);
            const tutorClassSnapshot = await getDoc(tutorClassRef);
            const tutorClassData = tutorClassSnapshot.data();

            if (tutorClassData && tutorClassData.scheduledClasses) {
                const updatedTutorClasses = tutorClassData.scheduledClasses.filter(item => item.start !== classItem.start);
                await updateDoc(tutorClassRef, { scheduledClasses: updatedTutorClasses });
                console.log(`5. Class canceled from tutor's document for student ${studentUID}.`);
            } else {
                console.warn(`Tutor document for ${tutorUID} does not contain scheduled classes for student ${studentUID}.`);
            }

            // Fetch calendarId from the tutor's document
            const tutorRef = doc(db, "users", tutorUID);
            const tutorSnapshot = await getDoc(tutorRef);
            const tutorData = tutorSnapshot.data();
            const calendarId = tutorData.calendarIds[0]; // Get the first calendarId
            console.log("6. Fetched calendar ID from tutor's document");

            // Now delete the event from Google Calendar using the eventId and calendarId
            const accessToken = localStorage.getItem('google_access_token');
            try {
                const deleteResponse = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events/${eventId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                console.log("7. Sent delete request to Google Calendar");

                if (!deleteResponse.ok) {
                    throw new Error('Failed to delete the event from Google Calendar');
                }
                console.log('8. Google Calendar event deleted successfully.');
            } catch (error) {
                console.error("Error deleting Google Calendar event:", error);
                // Continuar el proceso aunque falle la eliminación del evento en Google Calendar
            }

            // Restablecer el crédito del estudiante
            const tutorDocRef = doc(db, "users", studentUID, "tutors", tutorUID);
            const tutorDocSnap = await getDoc(tutorDocRef);
            console.log("9. Fetched student's tutor document from Firestore");

            if (tutorDocSnap.exists()) {
                const currentCredits = tutorDocSnap.data().credits || 0;
                await updateDoc(tutorDocRef, { credits: currentCredits + 1 });
                console.log(`10. Credit added back to student ${studentUID}.`);

                // Decrementar el número de clases del tutor
                const tutorMainDocRef = doc(db, "users", tutorUID);
                const tutorMainDocSnap = await getDoc(tutorMainDocRef);
                console.log("11. Fetched tutor's main document from Firestore");

                if (tutorMainDocSnap.exists()) {
                    const currentNumberOfLessons = tutorMainDocSnap.data().numberOfLessons || 0;
                    await updateDoc(tutorMainDocRef, {
                        numberOfLessons: currentNumberOfLessons > 0 ? increment(-1) : 0 // Decrementa solo si es mayor que 0
                    });
                    console.log(`12. Decremented one lesson for tutor ${tutorUID}.`);
                } else {
                    console.warn(`13. Main tutor document for ${tutorUID} does not exist.`);
                }

            } else {
                console.warn(`14. Tutor document for ${tutorUID} does not exist for student ${studentUID}`);
            }

        } catch (error) {
            console.error("Error during class cancellation: ", error);
            setError("Error during class cancellation: " + error.message);  // Actualiza el estado de error
        }
    };


    const calendarContextValue = {
        TIME_SLOT_DURATION,
        HOURS_START,
        HOURS_END,
        userTimeZone,
        currentWeek,
        setCurrentWeek,
        blockedSlots,
        availableSlots,
        unavailableSlots,
        selectedSlot,
        setSelectedSlot,
        isAuthenticated,
        isLoading,
        userData,
        error,
        accessToken,
        studentAccessToken,
        classData,
        eventId,
        studentEventId,
        users_tutor,
        users_student,
        clientId,
        clientSecret,
        refreshToken,
        studentRefreshToken,
        calendarIds,
        tutorWorkingHoursStart,
        tutorWorkingHoursEnd,
        tutorTimeZone,
        handleNextWeek,
        handlePreviousWeek,
        handleSlotClick,
        bookTrialClass,
        bookNormalClass,
        rescheduleClass,
        handleCancel,
        firestoreRoutes,
        InfoBeforeCalendarData,
        fetchBlockedSlots,
        requestAccessTokenWithRefreshToken,
        requestStudentAccessTokenWithRefreshToken,
        verifySelectedSlot,
        createClassFirestore,
        createGoogleCalendarEvent,
        updateGoogleCalendarEventIds,
        blockSelectedSlotsCalendarUI,
        verifyAlreadyTookTrial,
        CheckCredits,
        decrementCredits,
        incrementCredits,
        deleteClassFirestore,
        deleteGoogleCalendarEvent
    }


    return (
        <CalendarContext.Provider value={calendarContextValue}>
            {children}
        </CalendarContext.Provider>
    );

}